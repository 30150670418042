import React, { useState, useEffect } from "react";

export default function SubscribeForm() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  async function submitForm(e) {
    e.preventDefault();
    const form = document.getElementById("form-custom");
    const formData = new FormData(form);
    setIsButtonClicked(true);

    if (
      inputValue &&
      (isValidEmail(inputValue) || isValidPhoneNumber(inputValue))
    ) {
      try {
        await fetch(
          "https://script.google.com/macros/s/AKfycbw0McZQFsm0NXrPwqHGQ1s4zLVhibgFRrH5zJP_FQn881sAdkSKysaPTvfdl2msmm07/exec",
          {
            method: "POST",
            body: formData,
          }
        );
        setSuccessMessage("Thank you for subscribing! We'll be in touch.");
        setIsFormSubmitted(true);
        setInputValue("");
        setErrorMessage("");
        setTimeout(() => {
          setSuccessMessage("");
          setIsButtonClicked(false);
        }, 3000);
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    } else {
      setErrorMessage("Please enter a valid email or phone number.");
      setIsButtonClicked(true);
    }
  }

  function isValidEmail(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }

  function isValidPhoneNumber(value) {
    const phoneRegex =
      /^(\+?\d{1,3})?[-. ]?(\(\d{1,}\)|\d{1,})[-. ]?\d{1,}[-. ]?\d{1,}[-. ]?\d{1,}$/;
    return phoneRegex.test(value);
  }

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
        setIsButtonClicked(false);
      }, 3000);
    }
  }, [errorMessage]);

  return (
    <div>
      <form
        id="form-custom"
        name="form-custom"
        className="flex flex-col mt-2 md:flex-row items-center md:items-stretch"
        onSubmit={(e) => submitForm(e)}
      >
        <input
          type="text"
          className="form-control form-input border border-gray-800 focus:border-orange-500 focus:ring-orange-700 rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50 w-7/12 font-semibold bg-gray-200 p-2 min-w-72 md:min-w-80 lg:min-w-96"
          placeholder="Enter your Email/ Phone No."
          value={inputValue}
          name="Emails"
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button
          className={`btn ${
            isButtonClicked ? "bg-orange-600" : "bg-orange-500"
          } text-white transform transition-transform duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-md md:scale-95 p-2`}
          type="submit"
          onClick={() => setIsButtonClicked(true)}
        >
          Join Now
        </button>
      </form>
      <div>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        {isFormSubmitted && <p className="text-green-500">{successMessage}</p>}
      </div>
    </div>
  );
}
