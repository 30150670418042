import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";


ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({
  hitType: "pageview",
  page: "/contact-us",
  title: "contact-us-page",
});

export default function ContactUs() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [inputEmailValue, setInputEmailValue] = useState("");
  const [inputMessageValue, setInputMessageValue] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  async function submitForm(e) {
    e.preventDefault();
    const form = document.getElementById("contact-us-form");
    const formData = new FormData(form);
    setIsButtonClicked(true);

    let emailVerification =
      inputEmailValue &&
      (isValidEmail(inputEmailValue) || isValidPhoneNumber(inputEmailValue));

    if (emailVerification && inputMessageValue) {
      try {
        await fetch(
          "https://script.google.com/macros/s/AKfycbys2EzXm3ibxigDjM8Xv2ovNGXFwKFIPJqdxFzJiUHmUKOk8Lu52y45CLadBgnCD_jF/exec",
          {
            method: "POST",
            body: formData,
          }
        );

        setSuccessMessage("Thank you for your message! We'll get back to you.");
        setIsFormSubmitted(true);
        setInputMessageValue("");
        setErrorMessage("");

        setTimeout(() => {
          setIsButtonClicked(false);
          setSuccessMessage("");
        }, 2000);
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    } else {
      if (!emailVerification) {
        setErrorMessage("Please enter a valid email or phone number.");
      } else if (!inputMessageValue) {
        setErrorMessage("Message cannot be empty");
      }

      setIsButtonClicked(false);
    }
  }

  function isValidEmail(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }

  function isValidPhoneNumber(value) {
    const phoneRegex =
      /^(\+?\d{1,3})?[-. ]?(\(\d{1,}\)|\d{1,})[-. ]?\d{1,}[-. ]?\d{1,}[-. ]?\d{1,}$/;
    return phoneRegex.test(value);
  }

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  }, [errorMessage]);

  return (
    <>
      <Helmet>
        <title>WanderMeWell : Contact Us</title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <div id="ContactUs" className="container py-5  min-h-[73vh]">
        <div className="container">
          <div className="container heading flex justify-center p-3 px-2 w-full md:w-3/4 lg:w-1/2 mx-auto">
            <div>
              <h1 className="font-bold text-2xl md:text-4xl">Contact Us</h1>
              <hr className="w-1/2 md:w-1/4 border-t-4 border-orange-600 h-2" />
              <p className="text-lg md:text-lg">
                Let us know if you have any feedback, suggestion, or review
                about <span className="text-orange-600">WanderMeWell</span>.
                Thank You.
              </p>
              <br />
            </div>
          </div>

          <div className="px-2 w-full md:w-3/4 lg:w-1/2 mx-auto">
            <form
              className="mb-3 px-2"
              id="contact-us-form"
              name="contact-us-form"
              onSubmit={submitForm}
            >
              <div className="mb-3 flex flex-col">
                <label
                  htmlFor="input-name"
                  className="form-label text-lg font-bold"
                >
                  Name
                </label>
                <input
                  name="Name"
                  type="text"
                  className="form-input border border-gray-400 focus:border-blue-100 focus:ring-blue-500 rounded-md p-1 mt-2 focus:outline-none focus:ring-2 focus:ring-opacity-50 w-full bg-gray-200"
                  aria-describedby="fullName"
                  placeholder="Enter your name"
                  aria-label="Enter your name"
                />
              </div>
              <div className="mb-3 flex flex-col">
                <label
                  htmlFor="input-email"
                  className="form-label text-lg font-bold"
                >
                  Email address
                </label>
                <input
                  name="Email"
                  type="text"
                  className="form-input border border-gray-400 focus:border-blue-100 focus:ring-blue-500 rounded-md p-1 mt-2 focus:outline-none focus:ring-2 focus:ring-opacity-50 w-full bg-gray-200"
                  aria-describedby="emailHelp"
                  onChange={(e) => setInputEmailValue(e.target.value)}
                  placeholder="Enter your email / phone no."
                  aria-label="Enter your email / phone No."
                />
                <div id="emailHelp" className="text-gray-600">
                  We'll never share your email / phone no. with anyone else.
                </div>
              </div>
              <div className="mb-3 flex flex-col">
                <label
                  htmlFor="input-text"
                  className="form-label text-lg font-bold"
                >
                  Feedback / Suggestion / Review
                </label>
                <textarea
                  name="Message"
                  className="form-textarea border border-gray-400 focus:border-blue-100 focus:ring-blue-500 rounded-md p-1 mt-2 focus:outline-none focus:ring-2 focus:ring-opacity-50 w-full bg-gray-200"
                  rows="3"
                  placeholder="Enter message"
                  aria-label="Enter message"
                  onChange={(e) => setInputMessageValue(e.target.value)}
                  value={inputMessageValue}
                ></textarea>
              </div>
              <button
                className="btn z-0 inline-block py-2 px-4 rounded-lg shadow-lg text-white"
                style={{
                  backgroundColor: isButtonClicked ? "#f01a00" : "#f05a22",
                  transform: isButtonClicked ? "scale(0.95)" : "scale(1)",
                  transition: "background-color 0.3s, transform 0.3s",
                }}
                type="submit"
                id="contact-us-submit-button"
                onClick={() => setIsButtonClicked(true)}
              >
                Submit
              </button>
            </form>
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            {isFormSubmitted && (
              <p className="text-green-500">{successMessage}</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
