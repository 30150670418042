import React from "react";
import Landing from "../components/Landing";
import PopularPackages from "../components/PopularPackages";
import TravelingTip from "../components/TravelingTip";
import Trending from "../components/Trending";
import DownloadApp from "../components/DownloadApp";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";


function LandingPage() {
  return (
    <>
      <Helmet>
        <title>
          WanderMeWell : Smart AI tool for your trip planning and booking
        </title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <Landing />
      <PopularPackages />
      <TravelingTip />
      <Trending />
      <DownloadApp />
      <Footer />
    </>
  );
}

export default LandingPage;
