import React from "react";
import { FaTrash } from "react-icons/fa";

const TripCard = ({ uid, placeName, photoUrl, date, days, link, onDelete }) => {
  const [isPopupVisible, setIsPopupVisible] = React.useState(false);
  return (<div className="tripCard">
    <div className=" bg-white rounded-lg shadow-md p-4 relative">
      <a
        href={link}
        onClick={() => {
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        }}
      >
        <img
          src={photoUrl}
          alt="Trip"
          className="w-min-40 sm:w-min-32 md:w-min-48 h-48 object-cover mb-4"
        />
        <h3 className="text-lg font-bold mb-2 text-[#FD630D]">{placeName}</h3>
        <p className="text-gray-600">{date}</p>
        <p className="text-gray-600">{days}</p>
      </a>
      <button
        className="absolute top-2 right-2 bg-red-500 hover:bg-red-700 text-white p-2 rounded-full"
        onClick={() => setIsPopupVisible(true)}
      >
        <FaTrash />
      </button>
    </div>
    {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-md shadow-lg w-80">
            <h2 className="text-lg font-medium mb-2">Are you sure you want to Delete an Itinerary for {placeName} ?</h2>
            
            <div className="flex justify-end">
              <button
                className="bg-gray-500 text-white py-1 px-4 rounded-md mr-2"
                onClick={() => setIsPopupVisible(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-600 text-white py-1 px-4 rounded-md"
                onClick={()=>onDelete(uid)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
  </div>
  );
};

export default TripCard;
