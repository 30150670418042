import React from "react";
import aboutImage from "../assets/images/download-app.webp";
import appstorelogos from "../assets/images/download-app-buttons.webp";
import { Link } from "react-router-dom";

const DownloadApp = () => {

  return (
    <div className="pb-5 bg-black text-white">
      <div id="downloadApp" className="container pt-5 overflow-hidden">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 px-5">
            <div className="flex justify-center items-center">
              <img src={aboutImage} alt="" className="w-full md:max-w-md" />
            </div>
          </div>
          <div className="md:w-1/2 p-5 mt-6 md:mt-0 md:py-6 flex flex-col justify-center">
            <div>
              <h1 className="font-bold text-2xl md:text-3xl text-center mb-4">
                <span>Download our app now to start planning your travel</span>
                <br />
                <span>
                  With <span className="text-orange-500">WanderMeWell</span>
                </span>
              </h1>
              <p className="text-lg text-center">
                A place where AI will help you plan your travel!
              </p>
            </div>
            <div className="mt-6 flex justify-center items-center">
              <Link
                className="btn btn-primary btn-lg font-bold bg-[#0d6efd] p-4 rounded-lg w-7/12 flex justify-center items-center"
                to="/downloadApp"
              >
                Download Now
              </Link>
            </div>
            <div className="container mx-auto mt-6">
              <img
                src={appstorelogos}
                alt=""
                className="w-full md:max-w-sm mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
