import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useParams } from "react-router-dom";

const BookWithUs = ({ itinerary, defaultItinerary }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [inputEmailValue, setInputEmailValue] = useState("");
  const [inputNameValue, setInputNameValue] = useState("");
  const [inputLocationValue, setInputLocationValue] = useState("");
  const [inputMessageValue, setInputMessageValue] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buffer, setBuffer] = useState(false);

  const itineraryId = window.location.href;

  async function submitForm(event) {
    event.preventDefault();

    if (!isValidEmail(inputEmailValue)) {
      return setErrorMessage(
        inputEmailValue === ""
          ? "Email address should not be empty!"
          : "Please enter a valid email Id!"
      );
    }

    if (!isValidFullName(inputNameValue)) {
      return setErrorMessage(
        inputNameValue === ""
          ? "Please enter your name"
          : "Please enter your valid full name!"
      );
    }

    if (!phoneNumber || phoneNumber.length < 10) {
      return setErrorMessage("Please enter a valid mobile number!");
    }

    const formData = new FormData();
    setValidated(true);
    let dataVerification = inputEmailValue && isValidEmail(inputEmailValue);

    if (dataVerification) {
      setBuffer(true);
      try {
        formData.append("Email", inputEmailValue);
        formData.append("Name", inputNameValue);
        formData.append("Mobile", phoneNumber);
        formData.append("Location", inputLocationValue);
        formData.append("Message", inputMessageValue);
        formData.append("Itinerary",itineraryId);

        await fetch(
          "https://script.google.com/macros/s/AKfycbzwUSeKUESzkn5fkn559cekHfz2YueqRmT2h7J5uRSAxCZdPyHbIIiQEzBqxM_LYpDs/exec",
          {
            method: "POST",
            body: formData,
          }
        );

        swal(
          "Form Received",
          "Thank you for your message! We'll get back to you!",
          "success"
        ).then(() => {
          setBuffer(false);
          setIsFormSubmitted(true);
          setInputMessageValue("");
          setErrorMessage("");
          handleClose();
        });
      } catch (error) {
        console.error("Error submitting the form:", error);
        swal(
          "Not Submitted",
          "Error in submitting the form. Please try later.",
          "error"
        ).then(() => {
          setBuffer(false);
          handleClose();
        });
      }
    } else {
      setIsFormSubmitted(false);
      setBuffer(false);
      setErrorMessage("Please check all the fields once again.");
    }
  }

  function isValidFullName(value) {
    const fullNameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    return fullNameRegex.test(value);
  }

  function isValidEmail(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  }, [errorMessage]);

  const handleClose = () => {
    setInputEmailValue("");
    setSuccessMessage("");
    setInputLocationValue("");
    setInputNameValue("");
    setPhoneNumber("");
    setShowPopup(false);
  };

  return (
    <div className="book-with-us-button">
      <button
        type="button"
        className="z-50 cursor-pointer inline-block align-middle text-center select-none border font-semibold whitespace-no-wrap rounded py-1 px-2 leading-normal no-underline bg-[#fd630d] text-white hover:scale-110 my-3 transition-all"
        onClick={() => setShowPopup(true)}
      >
        Book With Us
      </button>

      {showPopup && (
        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen bg-gray-900 bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full sm:w-1/2">
              <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-center">
                <h5 className="text-xl font-medium leading-normal text-black">
                  Get a Quote
                </h5>
                <button
                  type="button"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <p className="p-4 text-lg">
                Let{" "}
                <span className="text-[#fd630d] font-semibold">
                  WanderMeWell
                </span>{" "}
                with its partner agents, handle the trip bookings for you.
              </p>
              <div className="px-4 py-3">
                <form
                  className="flex flex-col space-y-4"
                  noValidate
                  validated={validated}
                  onSubmit={submitForm}
                >
                  <div>
                    <label
                      className="block text-sm font-semibold text-black"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      autoFocus
                      value={inputEmailValue}
                      onChange={(e) => setInputEmailValue(e.target.value)}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#fd630d]"
                      placeholder="name@example.com"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-semibold text-black"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={inputNameValue}
                      onChange={(e) => setInputNameValue(e.target.value)}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#fd630d]"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-semibold text-black"
                      htmlFor="phone"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"in"}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                      inputProps={{
                        placeholder: "Enter your phone number"
                      }}
                      inputStyle={{
                        width: "100%",
                        padding: "0.5rem",
                        paddingLeft:"3rem",
                        border: "1px solid #D1D5DB",
                        borderRadius: "0.375rem",
                        outline: "none",
                        boxShadow: "0 0 0 1px rgba(253, 99, 13, 0.3)",
                      }}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-semibold text-black"
                      htmlFor="location"
                    >
                      Starting Location
                    </label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      value={inputLocationValue}
                      onChange={(e) => setInputLocationValue(e.target.value)}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#fd630d]"
                      placeholder="Bangalore, India"
                    />
                  </div>
                  <div>
                    <p className="text-sm text-gray-600">
                      Our agents currently support origin places from India
                      only. If you are outside of India, and visiting, we can
                      cover your itinerary in India as of now.
                    </p>
                  </div>
                  <div>
                    <label
                      className="block text-sm font-semibold text-black"
                      htmlFor="query"
                    >
                      Any further requirements, you wish to let us know.
                    </label>
                    <textarea
                      rows={3}
                      id="query"
                      name="query"
                      value={inputMessageValue}
                      onChange={(e) => setInputMessageValue(e.target.value)}
                      className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#fd630d]"
                    />
                  </div>
                  <div>
                    <p className="text-sm">
                      Our partner agents will reach out to you, soon.
                    </p>
                  </div>
                  <div className="mt-4">
                  {errorMessage && (
                    <p className="text-red-700 text-lg text-center">
                      {errorMessage}
                    </p>
                  )}
                  {isFormSubmitted && (
                    <p className="text-green-700 text-lg text-center">
                      {successMessage}
                    </p>
                  )}
                </div>
                  <button
                    type="submit"
                    className={`inline-block align-middle text-center select-none border font-normal rounded py-2 px-4 leading-normal no-underline ${
                      !buffer ? "bg-[#fd630d]" : "bg-orange-400"
                    } text-white my-3 align-center mx-auto`}
                  >
                    {!buffer
                      ? "Yes, Assist me with Bookings!"
                      : "Processing..."}
                  </button>
                  
                </form>
                
              </div>
              <div className="px-4 py-3 border-t border-gray-200 flex flex-row-reverse">
                <button
                  type="button"
                  className="bg-orange-500 text-white font-semibold py-1.5 px-5 rounded hover:opacity-75 focus:outline-none"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookWithUs;
