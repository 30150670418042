import * as React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TripItinerary from "./TripItinerary";
import { GrMoney } from "react-icons/gr";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineEmojiPeople as Solo } from "react-icons/md";
import { FaPeopleGroup as Friends } from "react-icons/fa6";
import { MdOutlineFamilyRestroom as Family } from "react-icons/md";
import { FaPeoplePulling as Couple } from "react-icons/fa6";
import { MdOutlineWorkOff as Vacation } from "react-icons/md";
import { MdOutlineFestival as Festival } from "react-icons/md";
import { MdOutlineWorkOutline as Work } from "react-icons/md";
import { IoCloseSharp as Close } from "react-icons/io5";
import "../assets/css/style.css";
import { MdCelebration as FamousIcon } from "react-icons/md";
import { GiHighlighter as HighlightIcon } from "react-icons/gi";
import { MdOutlineFestival as FestivalIcon } from "react-icons/md";
import { MdTipsAndUpdates as TipIcon } from "react-icons/md";
import { TbEmergencyBed as EmergencyIcon } from "react-icons/tb";
import { GoDotFill } from "react-icons/go";
import TravelOptions from "./TravelOptions";
import ExploreSights from "./ExploreSights";
import GetHotels from "./GetHotels";
import { Skeleton } from "@mui/material";
import { useState } from "react";
import axios from "axios";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabNavigation({
  date,
  days,
  about,
  itinerary,
  onCoordinatesChange,
  setActivity,
  setShowActivityModal,
  setShowMap,
  showMap,
  travelOptions,
  setItinerary,
  placeName,
  placeFormatted,
  exploreSights,
  sightsLoading,
  showRegenerateOption,
  currentHotel,
  setCurrentHotel,
  setShowCurrentHotelModal,
  setCurrentPicture,
}) {
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShowActivityModal(false);
    setShowCurrentHotelModal(false);
  };

  const [sortBy, setSortBy] = useState("number_of_reviews");
  const [showRegenerateItineraryModal, setShowRegenrateItineraryModal] =
    React.useState(false);

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [tripType, settripType] = useState("solo");
  const [activetripType, setActivetripType] = React.useState({
    solo: true,
    family: false,
    friends: false,
    couple: false,
  });

  const handletripType = (value) => {
    if (value === "solo") {
      setActivetripType({
        solo: true,
        family: false,
        friends: false,
        couple: false,
      });

      settripType(value);
    } else if (value === "family") {
      setActivetripType({
        solo: false,
        family: true,
        friends: false,
        couple: false,
      });

      settripType(value);
    } else if (value === "friends") {
      setActivetripType({
        solo: false,
        family: false,
        friends: true,
        couple: false,
      });

      settripType(value);
    } else if (value === "couple") {
      setActivetripType({
        solo: false,
        family: false,
        friends: false,
        couple: true,
      });

      settripType(value);
    }
  };

  const [budget, setBudget] = useState("medium");
  const [activeBudget, setActiveBudget] = React.useState({
    low: false,
    medium: true,
    high: false,
  });

  const handleBudget = (value) => {
    if (value === "low") {
      setActiveBudget({
        low: true,
        medium: false,
        high: false,
      });

      setBudget(value);
    } else if (value === "medium") {
      setActiveBudget({
        low: false,
        medium: true,
        high: false,
      });

      setBudget(value);
    } else if (value === "high") {
      setActiveBudget({
        low: false,
        medium: false,
        high: true,
      });

      setBudget(value);
    }
  };

  const [travellingPurpose, setTravellingPurpose] = useState("vacation");
  const [activeTravellingPurpose, setActiveTravellingPurpose] = React.useState({
    vacation: true,
    professional_work: false,
    event_festival: false,
  });

  const handleTravellingPurpose = (value) => {
    if (value === "vacation") {
      setActiveTravellingPurpose({
        vacation: true,
        professional_work: false,
        event_festival: false,
      });

      setTravellingPurpose(value);
    } else if (value === "professional_work") {
      setActiveTravellingPurpose({
        vacation: false,
        professional_work: true,
        event_festival: false,
      });

      setTravellingPurpose(value);
    } else if (value === "event_festival") {
      setActiveTravellingPurpose({
        vacation: false,
        professional_work: false,
        event_festival: true,
      });

      setTravellingPurpose(value);
    }
  };

  const [activities, setActivities] = useState([]);
  const [activeActivity, setActiveActivity] = React.useState({
    beaches: false,
    city_sightseeing: true,
    adventure: false,
    nightlife: false,
    shopping: false,
  });

  const handleActivities = (value) => {
    if (activities.includes(value)) {
      setActivities((prevActivities) =>
        prevActivities.filter((item) => item !== value)
      );
    } else {
      activities.push(value);
      setActivities(activities);
    }

    setActiveActivity((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  const priorItineraryDetails = async (req, res) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trip/get-prior-itinerary-details/${id}`,
        { withCredentials: false }
      );

      // console.log(response)
      setLatitude(response.data.message.lat);
      setLongitude(response.data.message.long);
      //setPlaceName(response.data.message.placeName)
      //setPlaceFormatted(response.data.message.placeFormatted)
      handleBudget(response.data.message.budget);
      handletripType(response.data.message.tripType);
      handleTravellingPurpose(response.data.message.travellingPurpose);
      setActivities(response.data.message.activities);
      response?.data?.message?.activities?.forEach((ele) => {
        setActiveActivity((prevState) => ({
          ...prevState,
          [ele]: true,
        }));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const regenerateItinerary = async () => {
    try {
      setItinerary(null);
      const details = {
        days: days,
        placeName: placeName,
        placeFormatted: placeFormatted,
        budget: budget,
        tripType: tripType,
        travellingPurpose: travellingPurpose,
        activities: activities,
        isRegenerate: true,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/itinerary/regenerate-itinerary/${id}`,
        details
      );
      setItinerary(response.data);
      window.location.reload();
      setShowRegenrateItineraryModal(false);
    } catch (error) {}
  };

  const [hotels, setHotels] = useState([]);
  const [hotelFetching, setHotelFetching] = useState(false);

  React.useEffect(() => {
    //console.log("Itinerary at TabNavigation ", itinerary);
    const GenerateActivity = async () => {
      try {
        const itineraryData = itinerary;

        // Function to fetch activity details using googlePlaceId
        const fetchActivityDetails = async (googlePlaceId) => {
          const activityResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/activity`,
            {
              googlePlaceId: googlePlaceId,
            }
          );
          return activityResponse.data;
        };

        // Loop through each day's activities and fetch their details concurrently
        const updatedItinerary = {
          ...itineraryData,
          itinerary: await Promise.all(
            itineraryData.itinerary.map(async (day) => {
              const updatedActivities = await Promise.all(
                day.activities.map(async (activity) => {
                  const activityDetails = await fetchActivityDetails(
                    activity.googlePlaceId
                  );
                  return { ...activity, details: activityDetails };
                })
              );

              return { ...day, activities: updatedActivities };
            })
          ),
        };

        // Update the state with the detailed itinerary
        //console.log(updatedItinerary);
        setItinerary(updatedItinerary);
      } catch (error) {
        console.error(error);
      }
    };
    // GenerateActivity();
    //console.log("New iti : ", itinerary);
    function extractStateAndCountry(location) {
      const parts = location.split(", ").map((item) => item.trim());

      if (parts.length === 2) {
        return { state: parts[0], country: parts[1] };
      } else if (parts.length === 1) {
        return { state: null, country: parts[0] }; // Assuming it's a country if only one entity is present
      }

      return { state: null, country: null }; // Default case for invalid input
    }
    const fetchHotels = async () => {
      await priorItineraryDetails();
      setHotelFetching(true);
      setTimeout(() => {}, 2000);
      //console.log("HOTELS fetching for ", placeName);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/searchHotels`,
          {
            city: placeName,
            state: extractStateAndCountry(placeFormatted).state,
            country: extractStateAndCountry(placeFormatted).country,
            sortBy: sortBy,
          }
        );
        fetchAndAppendPrices(response.data)
          .then((hotelsWithPrices) => {
            setHotels(hotelsWithPrices);
          })
          .catch((error) => {
            console.error("Error fetching hotel prices:", error);
          });
        setHotelFetching(false);
      } catch (error) {
        console.log(error.message);
      }
    };

    const calculateCheckOutDate = (checkInDate, numberOfNights) => {
      const checkIn = new Date(checkInDate);
      checkIn.setDate(checkIn.getDate() + numberOfNights);

      const year = checkIn.getFullYear();
      const month = (checkIn.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = checkIn.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    const fetchHotelDetails = async (hotel_id) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/hotelDetails`,
          {
            criteria: {
              additional: {
                currency: "INR",
                language: "en-us",
                occupancy: {
                  numberOfAdult: 2,
                  numberOfChildren: 0,
                },
              },
              checkInDate: date,
              checkOutDate: calculateCheckOutDate(date, 1),
              hotelId: [hotel_id],
            },
          }
        );
        const hotelDetails = response?.data?.results?.[0];
        return {
          ...hotelDetails,
          price: Math.round(hotelDetails?.dailyRate), // Convert the price to an integer using Math.round
        }; // Assuming the API returns a JSON object with multiple fields including `dailyRate` and `landingUrl`
      } catch (error) {
        console.error(`Error fetching details for hotel ${hotel_id}:`, error);
        return {}; // Return an empty object if the API call fails
      }
    };

    const fetchAndAppendPrices = async (hotels) => {
      const hotelPromises = hotels.map(async (hotel) => {
        const additionalInfo = await fetchHotelDetails(hotel.hotel_id);
        return { ...hotel, ...additionalInfo }; // Mergingthe original hotel object with additional information
      });

      const hotelsWithAdditionalInfo = await Promise.all(hotelPromises);
      return hotelsWithAdditionalInfo;
    };

    fetchHotels();
    // eslint-disable-next-line
  }, [placeName, sortBy, date, itinerary]);

  const UserId = localStorage.getItem("userId");
  const [currentUserId, setCurrentUserId] = useState(UserId);

  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [showSignInPopup, setShowSignInPopup] = useState(false); // State to show sign-in popup

  const loginCheck = () => {
    // Check login status on component mount
    const storedUsername = localStorage.getItem("userName");
    const storedUserId = localStorage.getItem("userId");

    if (storedUsername && storedUserId) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      setShowSignInPopup(true);
      setShowRegenrateItineraryModal(false);
    }
  };

  const handleButtonClick = (action) => {
    loginCheck();
    if (isLoggedIn) {
      action(); // Execute the action if logged in
    } else {
      setShowSignInPopup(true); // Show sign-in popup if not logged in
    }
  };
  
  const googleAuth = () => {
    const currentUrl = window.location.href;
    const redirectUrl = encodeURIComponent(currentUrl);

    // Construct the OAuth URL with itineraryId and userId as query parameters
    const actionData = encodeURIComponent(
      JSON.stringify({
        itineraryId: id,
      })
    );
    const action = "assignItinerary";
    const authUrl = `${process.env.REACT_APP_API_URL}/auth/google/callback?action=${action}&actionData=${actionData}`;

    // Open the URL in the same window
    window.open(authUrl, "_self");
  };

  return (
    <Box sx={{ width: "100%", marginBottom: "50px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
        >
          <Tab
            label="About"
            {...a11yProps(0)}
            onClick={() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
              setCurrentPicture(null);
            }}
          />
          <Tab
            label="Itinerary"
            {...a11yProps(1)}
            onClick={() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
              setCurrentPicture(null);
            }}
          />
          <Tab
            label="Hotels"
            {...a11yProps(2)}
            onClick={() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
              setCurrentPicture(null);
            }}
          />
          <Tab
            label="Explore Sights"
            {...a11yProps(3)}
            onClick={() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
              setCurrentPicture(null);
            }}
          />
          <Tab
            label="Travels"
            {...a11yProps(4)}
            onClick={() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
              setCurrentPicture(null);
            }}
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        {about ? (
          <div className="flex flex-col py-5 gap-y-10 px-4 items-center justify-center w-full">
            <span className="flex flex-col gap-y-2 items-start justify-center w-full">
              <span className="text-md lg:text-xl font-semibold tracking-wide">
                About {placeName}
              </span>
              <span className="text-sm">{about.aboutThePlace}</span>
            </span>

            <span className="flex flex-col gap-y-2 items-start justify-center w-full">
              <span className="text-base lg:text-base font-normal">
                <FamousIcon className="text-xl inline-block text-orange-500 mb-1 mr-1.5" />{" "}
                Famous for
              </span>
              {about.famousFor.map((ele, index) => {
                return (
                  <span
                    key={index}
                    className="flex gap-x-1.5 items-start justify-start text-sm"
                  >
                    <GoDotFill className="text-xs mt-1" />
                    <span className="text-sm">{ele}</span>
                  </span>
                );
              })}
            </span>

            <span className="flex flex-col gap-y-2 items-start justify-center w-full">
              <span className="text-base lg:text-base font-normal">
                <HighlightIcon className="text-xl inline-block text-orange-500 mb-1 mr-1.5" />{" "}
                Highlights
              </span>
              {about.highlight.map((ele, index) => {
                return (
                  <span
                    key={index}
                    className="flex gap-x-1.5 items-start justify-start text-sm"
                  >
                    <GoDotFill className="text-xs mt-1" />
                    <span className="text-sm">{ele}</span>
                  </span>
                );
              })}
            </span>

            <div className="flex flex-wrap justify-between w-full">
              <div className="flex flex-col gap-y-2 justify-start">
                <span className="text-base lg:text-base font-normal">
                  <EmergencyIcon className="text-xl inline-block text-orange-500 mb-1 mr-1.5" />{" "}
                  Emergency Contacts
                </span>
                <span className="flex gap-x-1.5 items-start justify-start text-sm">
                  <GoDotFill className="text-xs mt-1" />
                  <span className="text-sm">
                    Ambulance :- {about.emergencyNumbers.ambulanceNo}
                  </span>
                </span>
                <span className="flex gap-x-1.5 items-start justify-start text-sm">
                  <GoDotFill className="text-xs mt-1" />
                  <span className="text-sm">
                    Police :- {about.emergencyNumbers.policeNo}
                  </span>
                </span>
                <span className="flex gap-x-1.5 items-start justify-start text-sm">
                  <GoDotFill className="text-xs mt-1" />
                  <span className="text-sm">
                    Fire :- {about.emergencyNumbers.fireNo}
                  </span>
                </span>
              </div>

              <div className="my-2 md:my-1">
                <div className="w-full md:w-48 h-fit p-1 border-2 border-gray-500 mx-2 rounded-lg">
                  <a
                    href="https://safetywing.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-light mb-2"
                  >
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWbO1hHeot1FXdxFxeMCgpgKVnGIptV7gp8CB2TblCOg&s"
                      className="h-8"
                      alt="Error"
                    />
                  </a>
                  <p className="text-md">Your Travel Insurance</p>
                </div>
              </div>
            </div>

            <span className="flex flex-col gap-y-2 items-start justify-center w-full">
              <span className="text-base lg:text-base font-normal">
                <FestivalIcon className="text-xl inline-block text-orange-500 mb-1 mr-1.5" />{" "}
                Major Festivals
              </span>
              {about.majorFestivals.map((ele, index) => {
                return (
                  <span
                    key={index}
                    className="flex gap-x-1.5 items-start justify-start text-sm"
                  >
                    <GoDotFill className="text-xs mt-1" />
                    <span className="text-sm">{ele}</span>
                  </span>
                );
              })}
            </span>

            <span className="flex flex-col gap-y-2 items-start justify-center w-full">
              <span className="text-base lg:text-base font-normal">
                <TipIcon className="text-xl inline-block text-orange-500 mb-1 mr-1.5" />{" "}
                Tips for tourist
              </span>
              {about.tipsForTourist.map((ele, index) => {
                return (
                  <span
                    key={index}
                    className="flex gap-x-1.5 items-start justify-start text-sm"
                  >
                    <GoDotFill className="text-xs mt-1" />
                    <span className="text-sm">{ele}</span>
                  </span>
                );
              })}
            </span>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-y-3.5 items-start justify-center w-full px-2 py-8">
              <Skeleton
                className="w-full rounded-md"
                animation="wave"
                variant="rectangular"
                height={150}
              />
              <Skeleton
                className="w-32 mt-20 rounded-md"
                animation="wave"
                height={100}
              />
              <Skeleton
                className="w-full rounded-md"
                animation="wave"
                height={50}
              />

              <Skeleton
                className="w-32 mt-20 rounded-md"
                animation="wave"
                height={100}
              />
              <Skeleton
                className="w-full rounded-md"
                animation="wave"
                height={50}
              />
            </div>
          </>
        )}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        {itinerary ? (
          <>
            {showRegenerateOption && (
              <>
                <div
                  className="text-[#000000e0] py-1 px-5 mx-2.5 text-sm w-fit my-5 rounded-full border-2 border-double border-[#00000096] cursor-pointer hover:bg-gray-100"
                  onClick={() =>
                    handleButtonClick(() =>
                      setShowRegenrateItineraryModal(true)
                    )
                  }
                >
                  Regenerate Itinerary
                </div>
                <div
                  className={`${
                    showRegenerateItineraryModal ? "flex" : "hidden"
                  } px-3 w-full items-center justify-center`}
                >
                  <div className="flex flex-col items-center justify-center w-full gap-y-5 rounded-md shadow-sm border-2 border-[#00000096] border-solid">
                    <div className="relative flex items-center justify-between py-1.5 text-base bg-gray-100 text-left w-full px-3">
                      Let's build your itinerary
                      <Close
                        className="text-xl cursor-pointer hover:scale-150 duration-300"
                        onClick={() => setShowRegenrateItineraryModal(false)}
                      />
                    </div>

                    <div className="flex flex-col gap-y-2.5 items-start justify-center w-full px-3">
                      <span className="text-base text-black font-bold">
                        Who do you plan on travelling with?
                      </span>
                      <div className="flex flex-wrap gap-2.5 items-center justify-start w-full">
                        <span
                          className={`${
                            activetripType.solo
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handletripType("solo")}
                        >
                          <Solo className="text-lg" />
                          <h3 className="text-sm font-semibold">Solo</h3>
                        </span>

                        <span
                          className={`${
                            activetripType.family
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handletripType("family")}
                        >
                          <Family className="text-lg" />
                          <h3 className="text-sm font-semibold">Family</h3>
                        </span>

                        <span
                          className={`${
                            activetripType.friends
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start py-3 gap-x-1 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handletripType("friends")}
                        >
                          <Friends className="text-lg" />
                          <h3 className="text-sm font-semibold">Friends</h3>
                        </span>

                        <span
                          className={`${
                            activetripType.couple
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start py-3 px-4 gap-x-1 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handletripType("couple")}
                        >
                          <Couple className="text-lg" />
                          <h3 className="text-sm font-semibold">Couple</h3>
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2.5 items-start justify-center w-full px-3">
                      <span className="text-base text-black font-bold">
                        What's the purpose of travelling ?
                      </span>
                      <div className="flex flex-wrap gap-2.5 items-center justify-start w-full">
                        <span
                          className={`${
                            activeTravellingPurpose.vacation
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleTravellingPurpose("vacation")}
                        >
                          <Vacation className="text-lg" />
                          <h3 className="text-sm font-semibold">Vacation</h3>
                        </span>

                        <span
                          className={`${
                            activeTravellingPurpose.event_festival
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start py-3 px-2.5 w-40 gap-x-1 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() =>
                            handleTravellingPurpose("event_festival")
                          }
                        >
                          <Festival className="text-lg" />
                          <h3 className="text-sm font-semibold">
                            Event / Festival
                          </h3>
                        </span>

                        <span
                          className={`${
                            activeTravellingPurpose.professional_work
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-44 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() =>
                            handleTravellingPurpose("professional_work")
                          }
                        >
                          <Work className="text-lg" />
                          <h3 className="text-sm font-semibold">
                            Professional Work
                          </h3>
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2.5 items-start justify-center w-full px-3">
                      <span className="text-base text-black font-bold">
                        Which activities are you interested in ? You can select
                        multiple activities.
                      </span>
                      <div className="flex flex-wrap gap-2.5 items-center justify-start w-full">
                        <span
                          className={`${
                            activeActivity.beaches
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleActivities("beaches")}
                        >
                          <Solo className="text-lg" />
                          <h3 className="text-sm font-semibold">Beaches</h3>
                        </span>

                        <span
                          className={`${
                            activeActivity.adventure
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleActivities("adventure")}
                        >
                          <Friends className="text-lg" />
                          <h3 className="text-sm font-semibold">Adventure</h3>
                        </span>

                        <span
                          className={`${
                            activeActivity.nightlife
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleActivities("nightlife")}
                        >
                          <Couple className="text-lg" />
                          <h3 className="text-sm font-semibold">Nightlife</h3>
                        </span>

                        <span
                          className={`${
                            activeActivity.shopping
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleActivities("shopping")}
                        >
                          <Couple className="text-lg" />
                          <h3 className="text-sm font-semibold">Shopping</h3>
                        </span>

                        <span
                          className={`${
                            activeActivity.city_sightseeing
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-44 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleActivities("city_sightseeing")}
                        >
                          <Family className="text-lg" />
                          <h3 className="text-sm font-semibold">
                            City Sightseeing
                          </h3>
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col gap-y-2.5 items-start justify-center w-full px-3">
                      <span className="text-base text-black font-bold">
                        What's your budget, for travelling ?
                      </span>
                      <div className="flex flex-wrap gap-2.5 items-center justify-start w-full">
                        <span
                          className={`${
                            activeBudget.low
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start gap-x-1 py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleBudget("low")}
                        >
                          <GrMoney className="text-lg" />
                          <h3 className="text-sm font-semibold">Low</h3>
                        </span>

                        <span
                          className={`${
                            activeBudget.medium
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleBudget("medium")}
                        >
                          <GrMoney className="text-lg" />
                          <h3 className="text-sm font-semibold">Medium</h3>
                        </span>

                        <span
                          className={`${
                            activeBudget.high
                              ? "border-2 border-black"
                              : "border-[1px] border-gray-400"
                          } flex items-center justify-start py-3 px-2.5 w-28 rounded-md cursor-pointer border-solid hover:border-black`}
                          onClick={() => handleBudget("high")}
                        >
                          <GrMoney className="text-lg" />
                          <h3 className="text-sm font-semibold">High</h3>
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center justify-start w-full px-3 mb-5">
                      <button
                        className="py-1.5 px-3 cursor-pointer text-white tracking-wide font-semibold rounded-md bg-orange-500 hover:bg-orange-400"
                        onClick={() => regenerateItinerary()}
                      >
                        Regenerate
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            <TripItinerary
              date={date}
              itinerary={itinerary}
              onCoordinatesChange={onCoordinatesChange}
              setActivity={setActivity}
              setShowActivityModal={setShowActivityModal}
              setShowMap={setShowMap}
              showRegenerateOption={showRegenerateOption}
              setCurrentPicture={setCurrentPicture}
            />
            {/* Full-screen popup for login */}
            {!isLoggedIn && showSignInPopup && (
              <div
                className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black/75 z-[100]"
                onClick={() => setShowSignInPopup(false)} // Close popup when clicking outside
              >
                <div
                  className="relative bg-white p-6 pt-10 rounded-lg shadow-lg text-center"
                  onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
                >
                  <button
                    className="absolute top-3 right-3 text-3xl text-gray-800 hover:text-black"
                    onClick={() => setShowSignInPopup(false)} // Close button
                  >
                    &times;
                  </button>
                  <h2 className="text-2xl font-semibold mb-4">
                    Sign In Required
                  </h2>
                  <p className="text-lg">Please sign in to use this feature.</p>
                  <button
                    className="mt-4 px-6 py-2 bg-black text-white rounded-lg"
                    onClick={googleAuth}
                  >
                    <span className="flex items-center justify-center">
                      <FcGoogle className="mr-1 text-lg" />
                      <span translate="no">Sign In</span>
                    </span>
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex py-5 px-3 flex-col items-start justify-start h-screen overflow-y-scroll w-full hidden-scrollbar">
            <Skeleton className="w-full" animation="wave" height={50} />
            <Skeleton
              className="w-full"
              animation="wave"
              variant="rectangular"
              height={100}
            />
            <Skeleton className="w-32 mt-20" animation="wave" height={100} />
            <Skeleton
              className="w-full"
              animation="wave"
              variant="rectangular"
              height={100}
            />
            <Skeleton className="w-32 mt-20" animation="wave" height={100} />
            <Skeleton
              className="w-full"
              animation="wave"
              variant="rectangular"
              height={100}
            />
          </div>
        )}
      </CustomTabPanel>

      <CustomTabPanel value={value} index={2}>
        <div className="p-2 pb-8 md:pb-10 lg:pb-2">
          <div className="flex flex-wrap w-full justify-between items-center pb-2">
            <h1 className="text-lg font-bold text-center">
              Top Hotels in {placeName}
            </h1>
            <div className="flex flex-wrap items-center justify-center w-fit">
              <div className="w-fit flex flex-wrap gap-1">
                <div className="relative flex items-center justify-center text-sm text-left w-fit h-full py-1">
                  Sort by
                </div>
                <span
                  className={`px-2 text-sm py-1 hover:cursor-pointer border border-orange-500 rounded-md duration-500 ${
                    sortBy === "number_of_reviews"
                      ? "text-white bg-orange-500"
                      : "text-black"
                  }`}
                  onClick={() => setSortBy("number_of_reviews")}
                >
                  No of Reviews
                </span>
                <span
                  className={`px-2 text-sm py-1 hover:cursor-pointer border border-orange-500 rounded-md duration-500 ${
                    sortBy === "rating_average"
                      ? "text-white bg-orange-500"
                      : "text-black"
                  }`}
                  onClick={() => setSortBy("rating_average")}
                >
                  Average Rating
                </span>
                <span
                  className={`px-2 text-sm py-1 hover:cursor-pointer border border-orange-500 rounded-md duration-500 ${
                    sortBy === "star_rating"
                      ? "text-white bg-orange-500"
                      : "text-black"
                  }`}
                  onClick={() => setSortBy("star_rating")}
                >
                  Star Rating
                </span>
              </div>
            </div>
          </div>

          {!hotelFetching ? (
            <GetHotels
              setShowMap={setShowMap}
              hotels={hotels}
              onCoordinatesChange={onCoordinatesChange}
              currentHotel={currentHotel}
              setCurrentHotel={setCurrentHotel}
              setShowCurrentHotelModal={setShowCurrentHotelModal}
              setCurrentPicture={setCurrentPicture}
            />
          ) : (
            <div className="flex flex-col gap-y-3.5 items-start justify-center w-full px-2 py-8">
              <Skeleton
                className="w-full rounded-md"
                animation="wave"
                variant="rectangular"
                height={150}
              />
              <Skeleton
                className="w-full rounded-md"
                animation="wave"
                variant="rectangular"
                height={150}
              />
              <Skeleton
                className="w-full rounded-md"
                animation="wave"
                variant="rectangular"
                height={150}
              />
              <Skeleton
                className="w-full rounded-md"
                animation="wave"
                variant="rectangular"
                height={150}
              />
              <Skeleton
                className="w-full rounded-md"
                animation="wave"
                variant="rectangular"
                height={150}
              />
            </div>
          )}
        </div>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <ExploreSights
          sights={exploreSights}
          days={days}
          sightsLoading={sightsLoading}
          latitude={latitude}
          longitude={longitude}
          placeName={placeName}
          placeFormatted={placeFormatted}
          date={date}
          itinerary={itinerary}
          onCoordinatesChange={onCoordinatesChange}
          setActivity={setActivity}
          setShowActivityModal={setShowActivityModal}
          setShowMap={setShowMap}
          showRegenerateOption={showRegenerateOption}
          setCurrentPicture={setCurrentPicture}
          setCurrentUserId={setCurrentUserId}
        />
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        {travelOptions ? (
          <TravelOptions travelOptions={travelOptions} />
        ) : (
          <Skeleton />
        )}
      </CustomTabPanel>
    </Box>
  );
}
