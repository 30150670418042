import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function PopularPackages() {
  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/package/`
        );
        setPackageData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <section className="flex px-6 items-center justify-center w-screen py-12 sm:px-8 lg:px-10">
      <div className="flex flex-col items-center justify-center w-full max-w-7xl">
        <span className="flex items-center justify-center gap-x-3">
          <span className="mb-1 w-10 border-[#fd630d] border-2 rounded-2xl mt-2.5 border-solid"></span>
          <h1 className="text-xl font-bold font-sans text-black">
            Popular Packages
          </h1>
          <span className="mb-1 w-10 border-[#fd630d] border-2 rounded-2xl mt-2.5 border-solid"></span>
        </span>

        <span className="grid py-8 max-w-5xl w-full gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {packageData.slice(0, 6).map((packageItem, index) => (
            <Link key={"package_details_link_" + index} to={packageItem.link}>
              <span
                key={"package_details_link_span_" + index}
                className="cursor-pointer flex h-48 w-full overflow-hidden items-center justify-center relative sm:h-72 rounded-lg"
              >
                <img
                  key={"package_details_link_span_img_" + index}
                  className="absolute object-cover h-full w-full"
                  src={packageItem.photolink}
                  alt=""
                />
                <span
                  key={"package_details_link_span_span_" + index}
                  className="flex p-5 flex-col items-start justify-start absolute top-0 left-0 bg-blend-darken h-full w-full"
                  style={{
                    background: "linear-gradient(180deg, black, transparent)",
                  }}
                >
                  <h3
                    key={"package_details_link_span_h3_" + index}
                    className="text-gray-200 font-sans font-bold tracking-wider text-lg"
                  >
                    {packageItem.location}
                  </h3>
                  <span
                    key={"package_details_link_span_span_span_" + index}
                    className="w-8 mt-2"
                    style={{ border: "1.5px solid white" }}
                  ></span>
                </span>
              </span>
            </Link>
          ))}
        </span>
      </div>
    </section>
  );
}

export default PopularPackages;
