import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/style.css";
import { FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";

function FooterSmall() {
  return (
    <>
      <section className="z-0 md:z-10 bg-color-primary relative gap-y-4 flex-col flex items-center justify-center w-screen px-1">
        <div className="bg-black flex text-white items-center justify-center w-screen py-3.5 px-1">
          <span className="flex flex-col gap-y-5 items-center justify-between w-full lg:flex-row max-w-7xl">
            <span className="flex gap-x-3 items-start justify-start">
              <FiPhoneCall className="text-2xl" />
              <span className="text-xs sm:text-sm text-left">
                Call or message for any queries :  <br />
              </span>
              <span className="text-xs sm:text-sm text-left">
                +91 88676-27800 <br />
              </span>
            </span>
            <span className="flex items-center justify-center">
              <h4 className="text-sm lg:text-base">
                Follow us on : &nbsp; &nbsp;
              </h4>
              <span className="flex text-sm lg:text-base gap-x-1.5 items-center justify-center">
                <a
                  target="blank"
                  href="https://www.facebook.com/profile.php?id=100095249056060&mibextid=ZbWKwL"
                  className="p-2 cursor-pointer text-white hover:-translate-y-1 duration-300"
                >
                  <FaFacebookF className="text-lg" />
                </a>
                <a
                  target="blank"
                  href="https://youtube.com/@WanderMeWell?si=1-YdR_XD2U2fqCWI"
                  className="p-2 cursor-pointer text-white hover:-translate-y-1 duration-300"
                >
                  <FaYoutube className="text-lg" />
                </a>
                <a
                  target="blank"
                  href="https://www.instagram.com/wandermewell?igsh=MXR0aHI2MmQ3czQ3MA=="
                  className="p-2 cursor-pointer text-white hover:-translate-y-1 duration-300"
                >
                  <FaInstagram className="text-lg" />
                </a>
                <a
                  target="blank"
                  href="https://www.linkedin.com/company/wandermewell/"
                  className="p-2 cursor-pointer text-white hover:-translate-y-1 duration-300"
                >
                  <FaLinkedinIn className="text-lg" />
                </a>
              </span>
            </span>
          </span>
        </div>

        <div className="font-color-secondary flex items-center justify-center pb-2.5 w-screen  px-1">
          <span className="flex flex-col items-center justify-between w-full max-w-7xl lg:flex-row">
            <span className="text-sm font-semibold">
              © 2024 WanderMeWell, Inc
            </span>
            <span className="flex items-center justify-center">
              <span className="text-sm font-semibold cursor-pointer duration-300 hover:-translate-y-1">
                <a href="/contact-us">Help</a>
              </span>
              &nbsp; | &nbsp;
              <span className="text-sm font-semibold cursor-pointer duration-300 hover:-translate-y-1">
                <Link
                  className=" px-3 text-reset text-decoration-none"
                  to="/privacyPolicy#"
                  onClick={() => {
                    window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Privacy Policy
                </Link>
              </span>
              &nbsp; | &nbsp;
              <span className="text-sm font-semibold cursor-pointer duration-300 hover:-translate-y-1">
                <Link
                  className=" px-3 text-reset text-decoration-none"
                  to="/termsAndConditions"
                  onClick={() => {
                    window.scroll({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Terms and Conditions
                </Link>
              </span>
            </span>
          </span>
        </div>
      </section>
    </>
  );
}

export default FooterSmall;
