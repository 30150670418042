import React, { useState } from "react";
import { FaPlane, FaBed, FaUtensils, FaTaxi } from "react-icons/fa";
import BookNowFormPopup from "./BookNowFormPopup";

export default function TourPackages() {
  const packages = [
    {
      title: "Meghalaya via Guwahati (with Guide)",
      type: "Group trip(12)",
      description:
        "Experience the breathtaking landscapes and unique culture of Meghalaya, including the stunning waterfalls and crystal-clear rivers.",
      price: {
        3: "₹20,499",
        4: "₹22,499",
        5: "₹25,499",
      },
      cutPrice: {
        3: "₹22,599",
        4: "₹25,499",
        5: "₹30,000",
      },
      duration: "5N/6D",
      highlights: [
        "Day 1: Arrival at Guwahati & transfer to Shillong, visit Umiam Lake",
        "Day 2: Cherrapunjee transfer, Laitlum Canyon & Garden of Caves visit",
        "Day 3: Double Decker Trek",
        "Day 4: Dawki visit, Nohkalikai Falls & Mawlynnong Village",
        "Day 5: Water activities at Dawki & Krangsuri Waterfall",
        "Day 6: Departure from Shillong",
      ],
      locations: [
        "Guwahati",
        "Shillong",
        "Cherrapunjee",
        "Dawki",
        "Mawlynnong",
      ],
      imgSrc:
        "https://www.seawatersports.com/images/places/krang-suri-falls.png",
    },
    {
      title: "Meghalaya covering Kaziranga via Guwahati (with Guide)",
      type: "Group trip (12)",
      description:
        "Experience the breathtaking landscapes and unique culture of Meghalaya, including the stunning waterfalls, safaris, and crystal-clear rivers.",
      price: {
        3: "₹24,399",
        4: "₹26,499",
        5: "₹28,499",
      },
      cutPrice: {
        3: "₹26,899",
        4: "₹28,499",
        5: "₹30,999",
      },
      duration: "6N/7D",
      highlights: [
        "Day 1: Arrival at Guwahati & transfer to Shillong, visit Umiam Lake and explore Shillong market",
        "Day 2: Early morning safari at Kaziranga, transfer to Shillong via Umiam Lake",
        "Day 3: Cherrapunjee transfer, visit Laitlum Canyon & Garden of Caves",
        "Day 4: Double Decker Trek",
        "Day 5: Dawki visit, Nohkalikai Falls, Seven Sisters, Mawlynnong Village, and Living Root Bridge",
        "Day 6: Water activities at Dawki & Krangsuri Waterfall, overnight stay in Shillong",
        "Day 7: Departure from Shillong",
      ],
      locations: [
        "Guwahati",
        "Kaziranga",
        "Shillong",
        "Cherrapunjee",
        "Dawki",
        "Mawlynnong",
      ],
      imgSrc:
        "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/79/0b/29/living-root-bridge.jpg?w=1200&h=-1&s=1",
    },
    {
      title: "Meghalaya via Guwahati (with Guide)",
      type: "Group trip (10)",
      description:
        "Experience the breathtaking landscapes and unique culture of Meghalaya, including the stunning waterfalls and crystal-clear rivers.",
      price: {
        3: "₹25,299",
        4: "₹27,499",
        5: "₹29,499",
      },
      cutPrice: {
        3: "₹27,899",
        4: "₹29,499",
        5: "₹32,000",
      },
      duration: "5N/6D",
      highlights: [
        "Day 1: Arrival at Guwahati & transfer to Shillong, visit Umiam Lake",
        "Day 2: Cherrapunjee transfer, Laitlum Canyon & Garden of Caves visit",
        "Day 3: Double Decker Trek",
        "Day 4: Dawki visit, Nohkalikai Falls & Mawlynnong Village",
        "Day 5: Water activities at Dawki & Krangsuri Waterfall",
        "Day 6: Departure from Shillong",
      ],
      locations: [
        "Guwahati",
        "Shillong",
        "Cherrapunjee",
        "Dawki",
        "Mawlynnong",
      ],
      imgSrc:
        "https://media-cdn.tripadvisor.com/media/attractions-splice-spp-674x446/09/93/36/6e.jpg",
    },
    {
      title: "Meghalaya via Guwahati",
      type: "Group trip (10)",
      description:
        "Experience the breathtaking landscapes and unique culture of Meghalaya, including the stunning waterfalls and crystal-clear rivers.",
      price: {
        3: "₹21,399",
        4: "₹23,499",
        5: "₹25,499",
      },
      cutPrice: {
        3: "₹23,599",
        4: "₹25,499",
        5: "₹27,999",
      },
      duration: "5N/6D",
      highlights: [
        "Day 1: Arrival at Guwahati & transfer to Shillong, visit Umiam Lake",
        "Day 2: Cherrapunjee transfer, Laitlum Canyon & Garden of Caves visit",
        "Day 3: Double Decker Trek",
        "Day 4: Dawki visit, Nohkalikai Falls & Mawlynnong Village",
        "Day 5: Water activities at Dawki & Krangsuri Waterfall",
        "Day 6: Departure from Shillong",
      ],
      locations: ["Tawang", "Bomdila", "Dirang"],
      imgSrc:
        "https://economictimes.indiatimes.com/thumb/height-450,width-600,imgsize-180641,msid-53504169/bro-to-build-tunnels-on-bomdila-tawang-road.jpg?from=mdr",
    },
    {
      title: "Meghalaya via Guwahati",
      type: "Couple trip",
      description:
        "Experience the breathtaking landscapes and unique culture of Meghalaya, including the stunning waterfalls and crystal-clear rivers.",
      price: {
        3: "₹33,699",
        4: "₹35,499",
        5: "₹38,499",
      },
      cutPrice: {
        3: "₹37,099",
        4: "₹40,499",
        5: "₹42,999",
      },
      duration: "5N/6D",
      highlights: [
        "Day 1: Arrival at Guwahati & transfer to Shillong, visit Umiam Lake",
        "Day 2: Cherrapunjee transfer, Laitlum Canyon & Garden of Caves visit",
        "Day 3: Double Decker Trek",
        "Day 4: Dawki visit, Nohkalikai Falls & Mawlynnong Village",
        "Day 5: Water activities at Dawki & Krangsuri Waterfall",
        "Day 6: Departure from Shillong",
      ],
      locations: [
        "Guwahati",
        "Shillong",
        "Cherrapunjee",
        "Dawki",
        "Mawlynnong",
      ],
      imgSrc:
        "https://travenjo.com/wp-content/uploads/2022/06/Seven-Sisters-Falls-1-gaimg.jpg?x84619",
    },
  ];

  const [selectedRating, setSelectedRating] = useState(
    Array(packages.length).fill("3")
  );

  const handleRatingChange = (index, rating) => {
    const updatedRatings = [...selectedRating];
    updatedRatings[index] = rating;
    setSelectedRating(updatedRatings);
  };

  return (
    <div className="container mx-auto py-8 px-4">
      {packages.map((pkg, index) => (
        <div
          key={index}
          className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden mb-6"
        >
          {/* Left: Image Section */}
          <div className="md:w-1/2">
            <img
              src={pkg.imgSrc}
              alt={pkg.title}
              className="w-full h-full object-cover"
            />
          </div>

          {/* Right: Trip Details */}
          <div className="md:w-1/2 p-6">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold text-gray-800">{pkg.title}</h2>
              <span className="bg-orange-400 text-sm font-semibold py-1 px-3 rounded-full">
                {pkg.duration}
              </span>
            </div>

            <p className="text-md text-orange-400 font-bold my-2">{pkg.type}</p>

            <div className="text-blue-500 my-2 text-sm space-x-2">
              {pkg.locations.map((location, i) => (
                <span key={i}>
                  <a href="#">{location}</a>
                  {i < pkg.locations.length - 1 && " - "}
                </span>
              ))}
            </div>

            {/* Category Icons */}
            <div className="flex space-x-6 my-4">
              <div className="text-center relative">
                {/* New "Additional" text */}
                <span className="absolute -top-3 left-1/2 transform -translate-x-1/2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                  Additional
                </span>

                <div className="bg-blue-100 p-4 rounded-full">
                  <FaPlane className="text-blue-500 text-2xl" />
                </div>
                <p className="text-gray-600 text-sm">Flights</p>
              </div>

              <div className="text-center">
                <div className="bg-blue-100 p-4 rounded-full">
                  <FaBed className="text-blue-500 text-2xl" />
                </div>
                <p className="text-gray-600 text-sm">Stay</p>
              </div>
              <div className="text-center">
                <div className="bg-blue-100 p-4 rounded-full">
                  <FaUtensils className="text-blue-500 text-2xl" />
                </div>
                <p className="text-gray-600 text-sm">Meals</p>
              </div>
              <div className="text-center">
                <div className="bg-blue-100 p-4 rounded-full">
                  <FaTaxi className="text-blue-500 text-2xl" />
                </div>
                <p className="text-gray-600 text-sm">Transport</p>
              </div>
            </div>

            {/* Highlights Section */}
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="text-lg font-bold mb-2">Highlights</h3>
              <ul className="text-gray-700 text-sm space-y-1">
                {pkg.highlights.map((highlight, i) => (
                  <li key={i}>{highlight}</li>
                ))}
              </ul>
            </div>
          </div>

          {/* Pricing & Enquiry Section */}
          <div className="md:w-1/4 p-6 bg-gray-50 border-l">
            <div className="mb-4">
              <h3 className="text-md font-semibold mb-2">
                Select Hotel Rating
              </h3>
              <div className="flex flex-wrap justify-around">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name={`rating-${index}`}
                    value="3"
                    className="mr-2"
                    onChange={() => handleRatingChange(index, "3")}
                    checked={selectedRating[index] === "3"}
                  />
                  3 Star
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name={`rating-${index}`}
                    value="4"
                    className="mr-2"
                    onChange={() => handleRatingChange(index, "4")}
                    checked={selectedRating[index] === "4"}
                  />
                  4 Star
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name={`rating-${index}`}
                    value="5"
                    className="mr-2"
                    onChange={() => handleRatingChange(index, "5")}
                    checked={selectedRating[index] === "5"}
                  />
                  5 Star
                </label>
              </div>
            </div>

            {/* Pricing Section with Cut Price */}
            <div className="bg-blue-100 p-4 rounded-lg text-center mb-4">
              <p className="text-gray-700 text-sm">Starting At</p>
              <div className="flex justify-center items-center space-x-2">
                <p className="text-red-500 text-xl font-semibold line-through">
                  {pkg.cutPrice[selectedRating[index] || "3"]}
                </p>
                <p className="text-2xl font-bold text-gray-800">
                  {pkg.price[selectedRating[index] || "3"]}
                </p>
              </div>
              <p className="text-gray-500 text-sm">per person</p>
            </div>

            <BookNowFormPopup
              selectedPackage={pkg}
              selectedRating={selectedRating[index] || "3"}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
