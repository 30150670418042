import React from "react";
import "../assets/css/style.css";

function TravelingTip() {
  return (
    <section className="TravelTipsBg flex items-center justify-center w-screen py-12 px-6 sm:px-8 lg:px-10">
      <div className="flex items-center justify-center w-full gap-y-5 max-w-7xl lg:py-10">
        <span className="flex flex-col gap-y-10 items-center justify-between w-full lg:flex-row">
          <span className="flex flex-col items-start justify-center px-2 border-s-4 border-solid border-[#fd630d]">
            <h1 className="font-color-primary text-xl font-semibold font-sans sm:text-3xl">
              Get personal suggestions for your vacation
            </h1>
            <p className="font-color-secondary italic text-xs font-sans text-black sm:text-sm">
              Contact us for exciting bookings offers
            </p>
          </span>

          <span className="flex items-center justify-center gap-x-5">
            <button className="bg-color-secondary font-color-primary text-base flex items-center justify-center px-3 py-1.5 font-semibold  rounded-lg cursor-pointer hover:bg-gray-200">
              <a href="/contact-us">Talk to us</a>
            </button>
          </span>
        </span>
      </div>
    </section>
  );
}

export default TravelingTip;
