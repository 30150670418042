import React, { useEffect } from "react";
import { gsap } from "gsap";
import {Helmet} from 'react-helmet';
import { ScrollTrigger } from "gsap/all";
import img1 from "../assets/images/travel-planning-1.webp";
import img2 from "../assets/images/travel-booking-1.webp";
import img3 from "../assets/images/travel-booking-with-agent.webp";
import img4 from "../assets/images/travel-discovery.webp";
import img5 from "../assets/images/travel-insurance.webp";
import img6 from "../assets/images/tech-support.webp";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
gsap.registerPlugin(ScrollTrigger);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({
  hitType: "pageview",
  page: "/service",
  title: "service-page",
});


export default function ServicePage({ handleLoginPopup }) {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.utils.toArray(".card").forEach((card, index) => {
      gsap.fromTo(
        card,
        {
          delay: 1,
          translateX: index % 2 === 0 ? "-100px" : "100px",
          opacity: 0,
        },
        {
          delay: index % 2 === 0 ? 1 : 0.5,
          translateX: "0.75px",
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: card,
          },
        }
      );
    });

    gsap.fromTo(
      ".heading",
      { delay: 1, autoAlpha: 0 },
      {
        duration: 1,
        autoAlpha: 1,
        ease: "none",
        scrollTrigger: {
          trigger: ".heading",
        },
      }
    );
  }, []);

  const services = [
    {
      img: img1,
      title: "Travel Planning",
      description:
        "Don't know how to plan or what to do? Our AI enabled platform will help you plan every detail of your trip from start to finish, including hotel accommodations, transportation.",
    },
    {
      img: img2,
      title: "Travel Booking",
      description:
        "Book your next adventure with us! Choose from a wide selection of flights, hotels, and activities, and we'll take care of everything else.",
    },
    {
      img: img3,
      title: "Booking with Agent",
      description:
        "Talk to our experienced travel agents and get personalized recommendations and travel planning assistance based on your preferences, budget, and travel style.",
    },
    {
      img: img4,
      title: "Travel Discovery",
      description:
        "Not sure where to go for your next vacation? Let us help you find your dream destination based on your interests, budget, and travel style. From tropical beaches to urban adventures, we've got you covered.",
    },
    {
      img: img5,
      title: "Travel Insurance",
      description:
        "Protect your travel investment and enjoy peace of mind with our travel insurance options. Choose from a range of comprehensive plans that cover everything from flight cancellations to medical emergencies.",
    },
    {
      img: img6,
      title: "24/7 Customer Support",
      description:
        "Need help with your travel plans or have a question? Our friendly and knowledgeable customer support team is available 24/7 to assist you with anything you need.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          WanderMeWell : Smart AI tool for your trip planning and booking - our Services
        </title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <div className="mx-auto container">
        <div className="text-center pt-5 heading">
          <h1 className="text-3xl md:text-5xl font-bold py-2">
            Services We Provide
          </h1>
          <hr className="w-1/2 md:w-1/4 border-t-2 border-gray-400 mb-4 mx-auto" />
          <p className="text-lg md:text-xl text-black">
            Travel planning and booking can be a breeze with{" "}
            <span className="text-[#FD630D]">WanderMeWell</span>! Our
            all-inclusive travel services include planning with AI assistance,
            booking yourself or with agent, discovering your ideal travel
            destination, and so much more!
          </p>
        </div>

        <div className="grid grid-cols-1 gap-y-4 md:grid-cols-3  mt-11 mb-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 card"
            >
              <img
                className="rounded-t-lg h-72 w-full"
                src={service.img}
                alt=""
              />
              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  <p className="text-[#FD630D]">{index + 1}</p> {service.title}
                </h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
