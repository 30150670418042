import React, { useState, useEffect, useRef } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { IoLocationOutline, IoCalendarClearOutline } from "react-icons/io5";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import GoogleSearchBox from "./GoogleSearchBox";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function SearchWidget() {
  const navigate = useNavigate();
  const map = useRef(null);
  const handleValueChange = (newValue) => {
    setDateSelected(true);
    setDates(newValue);
  };

  const [showSearch, setShowSearch] = useState(false);
  const [id, setId] = useState(null);
  const [value, setValue] = useState("");
  const [data, setData] = useState(null);
  const [dateSelected, setDateSelected] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [dates, setDates] = useState({
    startDate: new Date().setDate(new Date().getDate() + 22),
    endDate: new Date().setMonth(11),
  });
  const [days, setDays] = useState("3 days");

  const defaultYear = new Date(
    new Date().setDate(new Date().getDate() + 22)
  ).getFullYear();
  const defaultMonth =
    new Date(new Date().setDate(new Date().getDate() + 22)).getMonth() + 1;
  const defaultDate = new Date(
    new Date().setDate(new Date().getDate() + 22)
  ).getDate();
  const defaultDateValue = `${defaultYear}-${
    defaultMonth < 10 ? `0${defaultMonth}` : defaultMonth
  }-${defaultDate < 10 ? `0${defaultDate}` : defaultDate}`;
  const postLocationDetails = async () => {
    setShowSearch(true);
    const locationDetails = {
      uid: id,
      placeName: data.structuredFormat.mainText.text,
      placeFormatted: data.structuredFormat.secondaryText
        ? data.structuredFormat.secondaryText.text
        : data.text.text,
      googlePlaceId: data.placeId,
      completePlaceName: data.text.text,
      date: dateSelected ? dates.startDate : defaultDateValue,
      days: days,
      completePrediction: data,
    };

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/trip/set-location-details`,
          locationDetails
        )
        .then((resp) => {
          setShowSearch(false);
          navigate(`/create-itinerary/${resp.data.message.uid}`);
        })
        .catch((err) => {
          setShowSearch(false);
          console.log(err);
        });
    } catch (error) {
      setShowSearch(false);
      console.error(error);
    }
  };

  const handlePostLocationDetails = () => {
    if (data !== null && value !== "") {
      postLocationDetails();
    } else {
      setShowEmptyError(true);
    }
  };

  useEffect(() => {
    const uid = uuidv4();
    setId(uid);
  }, []);

  return (
    <div className="bg-color-secondary drop-shadow-2xl my-2 mt-5 m-1 grid grid-cols-2 place-items-center rounded-none px-3 py-5 w-full sm:max-w-lg sm:rounded-lg">
      <span className="relative col-span-2 flex flex-col gap-y-1.5 items-start justify-center px-3.5 py-3.5 w-full ">
        <label
          className="flex items-center justify-start gap-x-1 text-base font-semibold"
          htmlFor=""
        >
          <IoLocationOutline className="font-color-primary text-lg sm:text-xl" />
          <span className="text-sm font-semibold sm:text-base">
            Destination
          </span>
        </label>

        <GoogleSearchBox
          input={value}
          setShowEmptyError={setShowEmptyError}
          setInput={setValue}
          data={data}
          setData={setData}
        />

        {showEmptyError ? (
          <span className="text-red-500 font-semibold text-xs italic mt-5 absolute bottom-0">
            * Please select a place from dropdown values
          </span>
        ) : (
          <></>
        )}
      </span>

      <span className="flex flex-col gap-y-1.5 items-start py-3.5 justify-center w-full">
        <label
          className="flex items-center justify-start px-3.5 gap-x-1 text-base font-semibold"
          htmlFor=""
        >
          <IoCalendarClearOutline className="font-color-primary text-lg sm:text-xl" />
          <span className="text-sm font-semibold sm:text-base">Start Date</span>
        </label>
        <span className="flex custom-css-datepicker gap-x-3 items-center justify-center px-2 w-full">
          <Datepicker
            classNames={"custom-css-datepicker"}
            asSingle={true}
            value={dates}
            onChange={handleValueChange}
            showShortcuts={false}
            primaryColor="orange"
            placeholder="Start Date"
            displayFormat={"DD/MM/YYYY"}
            disabledDates={[
              {
                startDate: "0000-00-00",
                endDate: new Date().setDate(new Date().getDate() - 1),
              },
            ]}
          />
        </span>
      </span>

      <span className="flex flex-col gap-y-1.5 items-start py-3.5 justify-center w-full h-full">
        <label
          className="flex items-center justify-start px-3.5 gap-x-1 text-base font-semibold"
          htmlFor=""
        >
          <HiOutlineCalendarDays className="font-color-primary text-lg sm:text-xl" />
          <span className="text-sm font-semibold sm:text-base">Duration</span>
        </label>
        <span className="flex gap-x-3 items-center justify-start px-2 w-full h-full lg:flex  ">
          <select
            title="hello"
            name="days"
            id="days"
            value={days}
            onChange={(ele) => setDays(ele.currentTarget.value)}
            className="no-of-days font-color-tertiary flex items-center font-medium text-sm py-2 px-2 justify-center w-full border-2 border-solid border-[#00000029] outline-none rounded-md"
          >
            <option value="1 day">1 day</option>
            <option value="2 days">2 days</option>
            <option defaultValue="true" value="3 days">
              3 days
            </option>
            <option value="4 days">4 days</option>
            <option value="5 days">5 days</option>
            <option value="6 days">6 days</option>
            <option value="7 days">7 days</option>
            <option value="8 days">8 days</option>
            <option value="9 days">9 days</option>
            <option value="10 days">10 days</option>
          </select>
        </span>
      </span>

      <Link
        className={`bg-color-primary col-span-2 flex items-center justify-center py-2.5 rounded-full ${
          showSearch ? "bg-orange-400" : "bg-orange-500"
        } hover:bg-orange-400 max-w-56 w-full mt-5`}
        onClick={() => handlePostLocationDetails()}
      >
        <button className="font-semibold text-base rounded-md cursor-pointer font-color-secondary flex gap-x-3 py-2 items-center justify-center px-6 lg:text-xl">
          {showSearch ? "Searching..." : "Search"}
        </button>
      </Link>
    </div>
  );
}

export default SearchWidget;
