import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../assets/css/style.css";
import { HiOutlineLocationMarker } from "react-icons/hi";
import Skeleton from "@mui/material/Skeleton";

function Trending() {
  const [activeTrending, setActiveTrending] = useState({
    hotels: true,
    tours: false,
    stays: false,
  });

  const [hotelsData, sethotelsData] = useState([]);
  const [trendingArrays, setTrendingArray] = useState(hotelsData);

  const fetchHotels = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/hotels/`
      );
      sethotelsData(
        response.data.map((ele, index) => {
          return (
            <Link
              key={index}
              to={ele.link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex cursor-pointer flex-col w-64 items-start justify-start border-slate-300 shadow-xl shadow-slate-300 border-t-0 border-2 rounded-lg"
            >
              <span className="relative w-full text-gray-300">
                <img
                  src={ele.photoUrl}
                  alt="error loading"
                  className="w-64 h-44 object-cover rounded-lg"
                />
              </span>

              <span className="flex text-black gap-y-1.5 flex-col w-full items-start px-2.5 py-3.5 justify-center">
                <span className="flex items-center text-gray-800 font-medium text-sm justify-center">
                  <HiOutlineLocationMarker className="mr-1.5" />
                  {ele.placeLocation}
                </span>
                <span className="font-sans font-semibold text-md text-left">
                  {ele.title}
                </span>
              </span>
            </Link>
          );
        })
      );

      setTrendingArray(
        response.data.map((ele, index) => {
          return (
            <Link
              key={index}
              to={ele.link}
              target="_blank"
              rel="noopener noreferrer"
              className="flex cursor-pointer flex-col w-64 items-start justify-start border-slate-300 shadow-xl shadow-slate-300 border-t-0 border-2 rounded-lg"
            >
              <span className="relative w-full text-gray-300">
                <img
                  src={ele.photoUrl}
                  alt="error loading"
                  className="w-64 h-44 object-cover rounded-md"
                />
              </span>

              <span className="flex text-black gap-y-1.5 flex-col w-full items-start px-2.5 py-3.5 justify-center">
                <span className="flex items-center text-gray-800 font-medium text-sm justify-center">
                  <HiOutlineLocationMarker className="mr-1.5" />
                  {ele.placeLocation}
                </span>
                <span className="font-sans font-semibold text-md text-left">
                  {ele.title}
                </span>
              </span>
            </Link>
          );
        })
      );
      //console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [toursData, settoursData] = useState([]);
  const fetchTours = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/tours/`
      );
      settoursData(
        response.data.map((ele, index) => {
          return (
            <Link
              to={ele.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              className="flex cursor-pointer flex-col w-64 items-start justify-start border-slate-300 shadow-xl shadow-slate-300 border-t-0 border-2 rounded-lg"
            >
              <span className="relative w-full text-gray-300">
                <img
                  src={ele.photoUrl}
                  alt="error loading"
                  className="w-64 h-44 object-cover rounded-md"
                />
              </span>

              <span className="flex text-black gap-y-1.5 flex-col w-full items-start px-2.5 py-3.5 justify-center">
                <span className="flex items-center text-gray-800 font-medium text-sm justify-center">
                  <HiOutlineLocationMarker className="mr-1.5" />
                  {ele.placeLocation}
                </span>
                <span className="font-sans font-semibold text-md text-left">
                  {ele.title}
                </span>
              </span>
            </Link>
          );
        })
      );
      //console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [staysData, setstaysData] = useState([]);
  const fetchStays = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/stays/`
      );
      setstaysData(
        response.data.map((ele, index) => {
          return (
            <Link
              to={ele.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              className="flex cursor-pointer flex-col w-64 items-start justify-start border-slate-300 shadow-xl shadow-slate-300 border-t-0 border-2 rounded-lg"
            >
              <span className="relative w-full text-gray-300">
                <img
                  src={ele.photoUrl}
                  alt="error loading"
                  className="w-64 h-44 object-cover rounded-md"
                />
              </span>

              <span className="flex text-black gap-y-1.5 flex-col w-full items-start px-2.5 py-3.5 justify-center">
                <span className="flex items-center text-gray-800 font-medium text-sm justify-center">
                  <HiOutlineLocationMarker className="mr-1.5" />
                  {ele.placeLocation}
                </span>
                <span className="font-sans font-semibold text-md text-left">
                  {ele.title}
                </span>
              </span>
            </Link>
          );
        })
      );
      //console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // fetching hotels
    fetchHotels();
    // fetching tours
    fetchTours();
    // fetching stays
    fetchStays();
  }, []);

  const clickonTrending = (given) => {
    if (given === "hotels") {
      setActiveTrending({
        hotels: true,
        tours: false,
        stays: false,
      });

      setTrendingArray(hotelsData);
    } else if (given === "tours") {
      setActiveTrending({
        hotels: false,
        tours: true,
        stays: false,
      });

      setTrendingArray(toursData);
    } else if (given === "stays") {
      setActiveTrending({
        hotels: false,
        tours: false,
        stays: true,
      });

      setTrendingArray(staysData);
    }
  };

  return (
    <>
      <section className="flex items-center justify-center w-screen py-12 px-6 sm:px-8 lg:py-16 lg:px-10">
        <div className="flex flex-col items-center justify-center w-full max-w-7xl ">
          <span className="flex items-center justify-center gap-x-3 mb-5">
            <span className="mb-1 w-10 border-[#fd630d] border-2 rounded-2xl mt-2.5 border-solid"></span>
            <h1 className="text-xl font-bold font-sans text-black">
              Trending
            </h1>
            <span className="mb-1 w-10 border-[#fd630d] border-2 rounded-2xl mt-2.5 border-solid"></span>
          </span>

          <ul className="flex items-center justify-center gap-x-3.5 gap-y-5 mb-3.5">
            <li
              className={`${
                activeTrending.hotels
                  ? "bg-color-primary font-color-secondary"
                  : "bg-transparent text-black border-orange-400 border-2 rounded-2xl"
              } cursor-pointer text-center rounded-full font-semibold text-sm font-sans py-1 px-5 md:text-md`}
              onClick={() => clickonTrending("hotels")}
            >
              Hotels
            </li>
            <li
              className={`${
                activeTrending.tours
                  ? "bg-color-primary font-color-secondary"
                  : "bg-transparent text-black border-orange-400 border-2 rounded-2xl"
              } cursor-pointer text-center rounded-full font-semibold text-sm font-sans py-1 px-5 md:text-md`}
              onClick={() => clickonTrending("tours")}
            >
              Tours
            </li>
            <li
              className={`${
                activeTrending.stays
                  ? "bg-color-primary font-color-secondary"
                  : "bg-transparent text-black border-orange-400 border-2 rounded-2xl"
              } cursor-pointer text-center rounded-full font-semibold text-sm font-sans py-1 px-5 md:text-md`}
              onClick={() => clickonTrending("stays")}
            >
              Stays
            </li>
          </ul>

          <div className="grid max-w-5xl md:w-full gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {hotelsData.length !== 0 &&
            toursData.length !== 0 &&
            staysData.length !== 0
              ? (trendingArrays)
              : 
              [...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    className="rounded-lg overflow-hidden shadow-md bg-white"
                  >
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={200}
                    />
                    <div className="p-4">
                      <div className="mb-4">
                        <Skeleton
                          className="h-4 w-1/2"
                          animation="wave"
                          variant="text"
                        />
                      </div>
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <Skeleton
                            className="h-8 w-8 rounded-full"
                            animation="wave"
                            variant="circle"
                          />
                        </div>
                        <div className="ml-3">
                          <Skeleton
                            className="h-4 w-20"
                            animation="wave"
                            variant="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Trending;
