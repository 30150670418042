import { createContext, useContext } from "react";
import swal from "sweetalert"
export const MyContext = createContext()



export const StatusProvider = ({children})=>{
    const showStatus =(title, message, type, isTimer = true) =>{
       return swal({
        title:title,
        message:message,
        isTimer,
        timer:300,
        icon:type
       })
    }
    return <MyContext.Provider
     value={{
      showStatus
    }}
    >
      {children}  
    </MyContext.Provider>

}

export const useStatus = () =>{
      return useContext(MyContext)
}