import React, { useState, useEffect } from "react";
import CommunityTripCard from "../components/CommunityTripsCard";
import defaultImageURL from "../assets/images/Manali.webp";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({
  hitType: "pageview",
  page: "/community-trips",
  title: "community-trips-page",
});

const CommunityTripsPage = () => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageno, setPageno] = useState(1);
  const [error, setError] = useState(null);
  const limit = 20;

  useEffect(() => {
    const fetchTrips = async () => {
      setLoading(true);
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        if (pageno === 1) {
          const getCoummunityTripPageCountResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/communityTrips/getCoummunityTripPageCount?limit=${limit}`,
            requestOptions
          );
          const getCoummunityTripPageCountResponseData =
            await getCoummunityTripPageCountResponse.json();

          if (getCoummunityTripPageCountResponseData) {
            setTotalPages(getCoummunityTripPageCountResponseData.count);
          }
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/communityTrips/getitineraries?pageno=${pageno}&limit=${limit}`,
          requestOptions
        );
        if (!response.ok) {
          console.log(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        if (data) {
          setTrips(data.itineraries);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTrips();
  }, [pageno]);

  const handlePrevPage = () => {
    if (pageno > 1) {
      setPageno(pageno - 1);
    }
  };

  const handleNextPage = () => {
    if (pageno < totalPages) {
      setPageno(pageno + 1);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">Loading...</div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-96">
        Error: {error}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>WanderMeWell: Community AI Travel Itinerary</title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        />
      </Helmet>
      <div className="z-50 container mx-auto px-5 py-2 md:px-10 lg:px-20 md:py-5 lg:pt-10 mt-5 min-h-[82vh]">
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-2">
            Discover your dream destinations with our beloved community!
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {trips &&
              trips.map((trip) => (
                <CommunityTripCard
                  key={trip.uid}
                  uid={trip.uid}
                  placeName={trip.placeName}
                  photoUrl={
                    trip.trip_itinerary?.itinerary?.[0]?.activities?.[0]?.photos
                      ?.length > 0
                      ? `https://places.googleapis.com/v1/${trip.trip_itinerary.itinerary[0].activities[0].photos[0].name}/media?maxHeightPx=1024&maxWidthPx=1920&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                      : defaultImageURL
                  }
                  date={trip.date}
                  days={trip.days}
                  link={`/itinerary/${trip.uid}`}
                  firstName={trip.user?.firstName}
                  lastName={trip.user?.lastName}
                  profilePhoto={trip.user?.photoUrl}
                />
              ))}
          </div>
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={handlePrevPage}
            disabled={pageno === 1}
            className="px-4 py-2 bg-gray-200 text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            ← Previous
          </button>
          <span className="mx-4">
            Page {pageno} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={pageno === totalPages}
            className="px-4 py-2 bg-gray-200 text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next →
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default CommunityTripsPage;
