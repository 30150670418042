import React, { useState, useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { IoLocationOutline, IoCalendarClearOutline } from "react-icons/io5";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import GoogleSearchBox from "./GoogleSearchBox";
import logo from "../assets/images/logo.png"
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({hitType: "pageview", page: "/",title: "home"});


function Landing() {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [data, setData] = useState(null);
  const [dateSelected, setDateSelected] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [dates, setDates] = useState({
    startDate: new Date().setDate(new Date().getDate() + 22),
    endDate: new Date().setMonth(11),
  });
  const [days, setDays] = useState("3 days");

  const defaultYear = new Date(
    new Date().setDate(new Date().getDate() + 22)
  ).getFullYear();
  const defaultMonth =
    new Date(new Date().setDate(new Date().getDate() + 22)).getMonth() + 1;
  const defaultDate = new Date(
    new Date().setDate(new Date().getDate() + 22)
  ).getDate();
  const defaultDateValue = `${defaultYear}-${
    defaultMonth < 10 ? `0${defaultMonth}` : defaultMonth
  }-${defaultDate < 10 ? `0${defaultDate}` : defaultDate}`;

  const handleValueChange = (newValue) => {
    setDateSelected(true);
    setDates(newValue);
  };

  const [showSearch, setShowSearch] = useState(false);
  const [isHotel, setIsHotel] = useState(false);
  const [id, setId] = useState(null);

  const postLocationDetails = async () => {
    setShowSearch(true);
    const locationDetails = {
      uid: id,
      placeName: data.structuredFormat.mainText.text,
      placeFormatted: data.structuredFormat.secondaryText
        ? data.structuredFormat.secondaryText.text
        : data.text.text,
      googlePlaceId: data.placeId,
      completePlaceName: data.text.text,
      date: dateSelected ? dates.startDate : defaultDateValue,
      days: days,
      completePrediction: data,
    };

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/trip/set-location-details`,
          locationDetails
        )
        .then((resp) => {
          setShowSearch(false);
          navigate(!isHotel 
            ? `/create-itinerary/${resp.data.message.uid}`
            : `/hotels/${resp.data.message.uid}`
          );
        })
        .catch((err) => {
          setShowSearch(false);
          console.log(err);
        });
    } catch (error) {
      setShowSearch(false);
      console.error(error);
    }
  };

  const handlePostLocationDetails = () => {
    if (data !== null && value !== "") {
      postLocationDetails();
    } else {
      setShowEmptyError(true);
    }
    //alert('Temporary service is unavailabel')
  };

  useEffect(() => {
    const uid = uuidv4();
    setId(uid);
  }, []);

  return (
    <>
      <section className="relative LandingBg flex items-center justify-center w-screen h-[28rem] sm:min-h-[40rem] lg:min-h-[30rem] md:py-8 py-2">
        <div className="flex flex-col px-0 items-center justify-end h-full w-full max-w-7xl sm:px-6 sm:justify-center">
          <div className=" sm:mb-0 flex flex-col justify-start md:justify-center items-center text-gray-400 font-medium text-center font-serif tracking-wide text-lg max-w-sm sm:max-w-none sm:text-2xl md:text-3xl">
            <img
              alt="wandermewell-logo"
              className="h-12 sm:h-32 sm:w-32"
              src={logo}
            />
            <h1 className="font-bold py-1">Dont just Travel, Wander!</h1>
          </div>

          <h3 className="text-lg font-serif hidden text-center font-light text-gray-400 max-w-[14rem] sm:max-w-[18rem] md:max-w-none sm:inline-block py-1">
            Get your free AI generated travel Itinerary planned with a few
            clicks.
          </h3>

          <div className="text-base text-center font-medium mt-3 flex flex-row w-full max-w-[24rem] ">
            <button
              className={`${
                !isHotel ? "bg-orange-600 text-white" : "bg-color-secondary"
              } w-1/2 py-2 px-8 border rounded-ss-md duration-300`}
              onClick={() => setIsHotel(false)}
            >
              Trip Planner
            </button>
            <button
              className={`${
                isHotel ? "bg-orange-600 text-white" : "bg-color-secondary"
              } w-1/2 py-2 px-8 border rounded-se-md duration-300`}
              onClick={() => setIsHotel(true)}
            >
              Find Hotels
            </button>
          </div>

          <span className="bg-color-secondary grid grid-cols-2 place-items-center rounded-none px-3 py-5 w-full sm:max-w-lg sm:rounded-lg lg:px-0 lg:py-0 lg:rounded-lg lg:max-w-5xl lg:grid-cols-5">
            <span className="relative col-span-2 flex flex-col gap-y-1.5 items-start justify-center px-3.5 py-3.5 w-full ">
              <label
                className="flex items-center justify-start gap-x-1 text-base font-semibold"
                htmlFor=""
              >
                <IoLocationOutline className="font-color-primary text-lg sm:text-xl" />
                <span className="text-sm font-semibold sm:text-base">
                  Destination
                </span>
              </label>

              <GoogleSearchBox
                input={value}
                setShowEmptyError={setShowEmptyError}
                setInput={setValue}
                data={data}
                setData={setData}
              />

              {showEmptyError ? (
                <span className="text-red-500 font-semibold text-xs italic mt-5 absolute bottom-0">
                  * Please select a place from dropdown values
                </span>
              ) : (
                <></>
              )}
            </span>

            <span className="flex flex-col gap-y-1.5 items-start py-3.5 justify-center w-full">
              <label
                className="flex items-center justify-start px-3.5 gap-x-1 text-base font-semibold"
                htmlFor=""
              >
                <IoCalendarClearOutline className="font-color-primary text-lg sm:text-xl" />
                <span className="text-sm font-semibold sm:text-base">
                  Start Date
                </span>
              </label>
              <span className="flex custom-css-datepicker gap-x-3 items-center justify-center px-2 w-full">
                <Datepicker
                  classNames={"custom-css-datepicker"}
                  asSingle={true}
                  value={dates}
                  onChange={handleValueChange}
                  showShortcuts={false}
                  primaryColor="orange"
                  placeholder="Start Date"
                  displayFormat={"DD/MM/YYYY"}
                  disabledDates={[
                    {
                      startDate: "0000-00-00",
                      endDate: new Date().setDate(new Date().getDate() - 1),
                    },
                  ]}
                />
              </span>
            </span>

            <span className="flex flex-col gap-y-1.5 items-start py-3.5 justify-center w-full h-full">
              <label
                className="flex items-center justify-start px-3.5 gap-x-1 text-base font-semibold"
                htmlFor=""
              >
                <HiOutlineCalendarDays className="font-color-primary text-lg sm:text-xl" />
                <span className="text-sm font-semibold sm:text-base">
                  Enter no. of days
                </span>
              </label>
              <span className="flex gap-x-3 items-center justify-start px-2 w-full h-full lg:flex  ">
                <select
                  title="hello"
                  name="days"
                  id="days"
                  value={days}
                  onChange={(ele) => setDays(ele.currentTarget.value)}
                  className="no-of-days font-color-tertiary flex items-center font-medium text-sm py-2 px-2 justify-center w-full border-2 border-solid border-[#00000029] outline-none rounded-md"
                >
                  <option value="1 day">1 day</option>
                  <option value="2 days">2 days</option>
                  <option defaultValue="true" value="3 days">
                    3 days
                  </option>
                  <option value="4 days">4 days</option>
                  <option value="5 days">5 days</option>
                  <option value="6 days">6 days</option>
                  <option value="7 days">7 days</option>
                  <option value="8 days">8 days</option>
                  <option value="9 days">9 days</option>
                  <option value="10 days">10 days</option>
                </select>
              </span>
            </span>

            <Link
              className={`bg-color-primary col-span-2 flex items-center justify-center py-2.5 rounded-full ${
                showSearch ? "bg-orange-400" : "bg-orange-500"
              } hover:bg-orange-400 max-w-56 w-full mt-5 lg:rounded-e-lg lg:rounded-s-none lg:mt-0 lg:py-3.5 lg:h-full lg:max-w-none lg:col-span-1`}
              onClick={() => handlePostLocationDetails()}
            >
              <button className="font-semibold text-base rounded-md cursor-pointer font-color-secondary flex py-1 items-center justify-center px-4 lg:text-xl">
                {showSearch
                  ? !isHotel
                    ? "Creating..."
                    : "Searching..."
                  : !isHotel
                  ? "Let's Plan"
                  : "Search"}
              </button>
            </Link>
          </span>
        </div>
      </section>
    </>
  );
}

export default Landing;