import React from "react";
import TourPackages from "../components/TourPackages";
import TravelingTip from "../components/TravelingTip";
import BookNowForm from "../components/BookNowForm";
import Footer from "../components/Footer";
import Testimonial from "../components/Testimonial";
import video from "../assets/video/MeghalayaTravelPageFinalvideo.mp4";

function TravelPage() {
  return (
    <>
      <div className="w-full min-h-[60vh] py-4">
        <video
          className="w-full h-[45vh] lg:h-[75vh] mx-auto"
          controls
          autoPlay
          loop
          muted
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <TravelingTip />
      <TourPackages />
      <Testimonial />
      <BookNowForm />
      <Footer />
    </>
  );
}

export default TravelPage;
