import React from "react";
import Logo from "../assets/images/logo.png";

function BlogCard({ data }) {
  return (
    <div className="p-3 w-full md:w-1/3 sm:w-1/2">
      <div className="h-100  border-2 border-gray-300 shadow-md border-opacity-60 rounded-lg overflow-hidden">
        <img
          className="lg:h-auto md:h-36 w-1/2 mx-auto object-cover object-center"
          src={Logo}
          alt="blog"
        />
        <div className="p-3">
          <h2 className="tracking-widest text-xs title-font font-bold text-[#fd630d] text-center mb-1">
            WanderMeWell
          </h2>
          <a href={`/blog/${data.link}`} className="w-full mb-3">
            <h1 className="overflow-hidden sm:text-sm md:text-sm lg:text-md title-font font-medium text-gray-900 text-center h-12 lg:h-16 align-baseline">
              {data.title}
            </h1>
          </a>
          <div className="flex items-center flex-wrap ">
            <a
              href={`/blog/${data.link}`}
              className="text-[#fd630d] sm:text-sm md:text-sm lg:text-md inline-flex items-center cursor-pointer font-semibold pt-2"
            >
              Read More
              <svg
                className="w-3 h-3 ml-2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          <span className="text-gray-900 text-xs sm:text-sm md:text-xs inline-flex font-semibold justify-center leading-none text-sm pt-1">
            18/03/24
          </span>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
