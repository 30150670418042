import React, { useState, useEffect, useRef } from "react";
import { IoMdClose as Close } from "react-icons/io";
import { FaStar, FaSearch, FaFilter } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../assets/css/style.css";

export default function ExploreSights({
  placeName,
  days,
  latitude,
  longitude,
  date,
  sights,
  sightsLoading,
  placeFormatted,
  itinerary,
  onCoordinatesChange,
  setActivity,
  setShowActivityModal,
  setShowMap,
  showRegenerateOption,
  setCurrentPicture,
  CurrentUserId,
}) {
  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [uniqueSightTypes, setUniqueSightTypes] = useState([]);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [filter, setFilter] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSight, setSelectedSight] = useState(null);
  const { id } = useParams();
  const { itineraryId } = useParams();
  const userId = CurrentUserId;
  const [hoveredActivity, setHoveredActivity] = useState(null);
  const dropdownRefs = useRef([]);
  const [picture, setPicture] = useState(null);

  // Map to store googlePlaceIds vs days for itinerary activities
  const placeIdToDayMap = useRef(new Map());

  // Map to store googlePlaceIds vs sights
  const placeIdToSightMap = useRef(new Map());

  // Populate the itinerary activity map
  useEffect(() => {
    const map = new Map();
    itinerary?.forEach((item, index) => {
      item.activities.forEach((activity) => {
        if (activity.googlePlaceId) {
          map.set(activity.googlePlaceId, index);
        }
      });
    });
    placeIdToDayMap.current = map;
  }, [itinerary]);

  // Populate the sights map
  useEffect(() => {
    const map = new Map();
    sights?.forEach((sight) => {
      const foundDayIndex = itinerary?.findIndex((item) =>
        item.activities.some(
          (activity) => activity.googlePlaceId === sight.googlePlaceId
        )
      );
      map.set(sight.googlePlaceId, foundDayIndex !== -1 ? foundDayIndex : -1);
    });
    placeIdToSightMap.current = map;
  }, [sights, itinerary]);

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [showSignInPopup, setShowSignInPopup] = useState(false);

  const toggleDateDropdown = async (index) => {
    const loggedIn = loginCheck(); 
    if (!loggedIn) {
      setShowSignInPopup(true);
      return;
    }
    setDropdownOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const formatText = (text) => {
    return text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const WeekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const Months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const startingDate = new Date(date);

  const getDay = (index) => WeekDays[(startingDate.getDay() + index) % 7];

  const getMonth = (index) => {
    const newDate = new Date(startingDate);
    newDate.setDate(startingDate.getDate() + index);
    return Months[newDate.getMonth()];
  };

  const getDate = (index) => {
    const newDate = new Date(startingDate);
    newDate.setDate(startingDate.getDate() + index);
    return newDate.getDate();
  };

  const fetchActivityDetails = async (googlePlaceId) => {
    const activityResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/activity`,
      {
        googlePlaceId: googlePlaceId,
      }
    );
    return activityResponse.data;
  };

  const loginCheck = () => {
    const storedUsername = localStorage.getItem("userName");
    const storedUserId = localStorage.getItem("userId");

    return !!(storedUsername && storedUserId); 
  };

  const handleSelectClick = async (e, sight, dayIndex) => {
    const loggedIn = loginCheck(); 
    if (!loggedIn) {
      setShowSignInPopup(true);
      return;
    }

    setSelectedDate(dayIndex);
    setSelectedSight(sight);

    const isSightInItinerary = itinerary?.some((item) =>
      item.activities.some(
        (activity) => activity.googlePlaceId === sight.googlePlaceId
      )
    );

    if (isSightInItinerary) {
      const sightDate = getSightDateInItinerary(sight);
      if (dayIndex !== -1 && sight) {
        const dayId = itinerary[dayIndex]?.did;
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/sleeve/moveActivity`,
            {
              uid: id,
              fromDayId: itinerary[sightDate.dayIndex].did,
              toDayId: itinerary[dayIndex].did,
              googlePlaceId: sight.googlePlaceId,
            }
          );
          const activityIndex = itinerary[dayIndex].activities.findIndex(
            (activity) => activity.googlePlaceId === sight.googlePlaceId
          );

          const activity = itinerary[sightDate.dayIndex].activities.splice(
            activityIndex,
            1
          )[0];
          itinerary[dayIndex].activities.push(activity);
        } catch (error) {
          console.error("Error moving activity:", error);
        }
      }
    } else {
      if (dayIndex !== -1 && sight) {
        const dayId = itinerary[dayIndex]?.did;
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/sleeve/addActivity`,
            {
              uid: id,
              dayId: dayId,
              googlePlaceId: sight.googlePlaceId,
              timings: "09:00-11:00",
            },
            {
              withCredentials:true
            }
          );

          const details = await fetchActivityDetails(sight.googlePlaceId);
          itinerary[dayIndex].activities.push({
            googlePlaceId: sight.googlePlaceId,
            timings: "09:00-11:00",
            details: details,
          });
        } catch (error) {
          console.error("Error adding activity:", error);
        }
      }
    }
  };

  const handleDeleteButtonClick = async (e, sight, dayIndex, googlePlaceId) => {
    const loggedIn = loginCheck(); 
    if (!loggedIn) {
      setShowSignInPopup(true);
      return;
    }

    if (!googlePlaceId) {
      console.error("googlePlaceId is undefined");
      return;
    }

    const dayId = itinerary[dayIndex]?.did;

    try {
      const activityIndex = itinerary[dayIndex].activities.findIndex(
        (activity) => activity.googlePlaceId === googlePlaceId
      );

      if (activityIndex === -1) {
        console.error("Activity not found in itinerary before api call");
        return;
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/sleeve/deleteActivity`,
        {
          uid: id,
          dayId,
          googlePlaceId,
        },
        {
          withCredentials:true
        }
      );

      itinerary[dayIndex].activities.splice(activityIndex, 1);
    } catch (error) {
      console.error("Error deleting activity:", error);
    }
  };

  const getAvailableDates = (sight) => {
    return itinerary
      .map((item, dayIndex) => ({ ...item, dayIndex })) 
      .filter(
        (item) =>
          !item.activities.some(
            (activity) => activity.googlePlaceId === sight.googlePlaceId
          )
      );
  };

  const getSightDateInItinerary = (sight) => {
    for (let dayIndex = 0; dayIndex < itinerary?.length; dayIndex++) {
      if (
        itinerary[dayIndex].activities.some(
          (activity) => activity.googlePlaceId === sight.googlePlaceId
        )
      ) {
        return {
          dayIndex,
          day: getDay(dayIndex),
          month: getMonth(dayIndex),
          date: getDate(dayIndex),
        };
      }
    }
    return null;
  };

  const handleClickOutside = (e) => {
    dropdownRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(e.target)) {
        setDropdownOpen((prevState) => ({
          ...prevState,
          [index]: false,
        }));
      }
    });
  };

  const handleActivityDetails = (givenActivity) => {
    setShowMap(true);
    setShowActivityModal(true);
    setActivity(givenActivity);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const uniqueTypesSet = new Set();
    const uniqueSightTypes = [];
    sights?.forEach((sight) => {
      if (sight.types) {
        sight.types.forEach((type) => {
          if (!uniqueTypesSet.has(type)) {
            uniqueTypesSet.add(type);
            uniqueSightTypes.push(type);
          }
        });
      }
    });
    setUniqueSightTypes(uniqueSightTypes);

    if (!sights || sights.length === 0) {
      setError("No sights found");
    }

    const handleActivityHover = (activity) => {
      if (!activity) return;
      const coordinates = activity.location;
      if (coordinates) {
        onCoordinatesChange(coordinates);
      }
    };
    handleActivityHover(hoveredActivity);
  }, [hoveredActivity, onCoordinatesChange, sights]);

  const filteredSights = sights?.filter((sight) => {
    if (!sight) return false;
    const matchesSearch =
      sight.displayName?.text &&
      sight.displayName.text.toLowerCase().includes(search.toLowerCase());

    const matchesFilter =
      filter.length === 0 || sight.types.some((type) => filter.includes(type));

    return matchesSearch && matchesFilter;
  });

  const handleClick = (type) => {
    setFilter((prevFilter) =>
      prevFilter.includes(type)
        ? prevFilter.filter((item) => item !== type)
        : [...prevFilter, type]
    );
  };

  const formattedDate = date
    ? new Date(date).toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
      })
    : "";

  if (sightsLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const googleAuth = () => {
    const currentUrl = window.location.href;
    const redirectUrl = encodeURIComponent(currentUrl); 

    // Construct the OAuth URL with itineraryId and userId as query parameters
    const actionData = encodeURIComponent(
      JSON.stringify({
        itineraryId: id,
      })
    );
    const action = "assignItinerary";
    const authUrl = `${process.env.REACT_APP_API_URL}/auth/google/callback?action=${action}&actionData=${actionData}`;

    // Open the URL in the same window
    window.open(authUrl, "_self");
  };

  return (
    <div className="p-6">
      {itinerary ? (
        <>
          {/* Header with Filter Button */}
          <div className="w-full h-auto flex flex-row justify-between items-center mb-2">
            <h1 className="text-lg font-semibold text-center">
              Top Sights in {placeName}
            </h1>
            <button
              className="flex justify-center items-center w-10 h-10 bg-orange-500 focus:outline-none p-2 rounded-full"
              type="button"
              onClick={toggleFilterDropdown}
            >
              <FaFilter className="text-white" size={20} />
            </button>
          </div>

          {/* Filter Dropdown */}
          {filterDropdownOpen && (
            <div className="dropdown-menu duration-200 transition-all flex flex-row flex-wrap gap-2 my-2 w-full text-black rounded shadow">
              {uniqueSightTypes.map((type, index) => (
                <span
                  key={index}
                  className={`block w-fit text-left px-2 cursor-pointer py-1 border border-black rounded-lg ${
                    filter.includes(type)
                      ? "bg-orange-500 text-white"
                      : "bg-white text-black"
                  }`}
                  onClick={() => handleClick(type)}
                >
                  {formatText(type)}
                </span>
              ))}
            </div>
          )}

          {/* Sight Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredSights?.map((sight, index) => (
              <div
                key={sight.id}
                className="relative bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full"
              >
                {/* Sight Image */}
                <img
                  src={`https://places.googleapis.com/v1/${sight?.photos?.[0]?.name}/media?maxHeightPx=400&maxWidthPx=600&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                  onClick={() =>
                    setPicture(
                      `https://places.googleapis.com/v1/${sight?.photos?.[0]?.name}/media?maxHeightPx=800&maxWidthPx=1200&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                    )
                  }
                  alt={sight.displayName.text}
                  className="w-full h-40 object-cover"
                />
                {/* Sight Name Overlay */}
                <div className="absolute top-0 left-0 w-full bg-black bg-opacity-50 text-white p-2">
                  <h2
                    className="text-md font-medium cursor-pointer"
                    onMouseEnter={() => {
                      setHoveredActivity(sight);
                      setCurrentPicture(
                        `https://places.googleapis.com/v1/${sight?.photos?.[0]?.name}/media?maxHeightPx=800&maxWidthPx=1200&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                      );
                    }}
                    onMouseLeave={() => setHoveredActivity(null)}
                    onClick={() => handleActivityDetails(sight)}
                  >
                    {sight.displayName.text}
                    <span className="block lg:hidden text-[10px] text-gray-300">
                      Click to View details
                    </span>
                  </h2>
                </div>
                <div className="p-4 flex-grow">
                  {/* Sight Summary */}
                  <p className="text-sm text-gray-600 mb-2">
                    {sight.editorialSummary?.text ||
                      sight.remarks ||
                      "No summary available"}
                  </p>
                  {/* Sight Rating */}
                  <div className="flex items-center mb-4">
                    <FaStar className="text-yellow-400 mr-1" />
                    <span className="text-gray-600">
                      {sight.rating}/5 rating
                    </span>
                  </div>
                </div>
                {/* Add to Day Button */}
                {showRegenerateOption && (
                  <div
                    className="mt-auto p-4"
                    ref={(el) => (dropdownRefs.current[index] = el)}
                  >
                    <button
                      className="flex w-full items-center justify-between rounded-md border border-solid border-gray-300 px-3 py-1 text-start text-sm hover:border-gray-400 md:text-md bg-gray-50 text-gray-500 hover:text-gray-700"
                      onClick={() => toggleDateDropdown(index)}
                    >
                      {(() => {
                        const sightDate = getSightDateInItinerary(sight);
                        return sightDate
                          ? `${sightDate.day}, ${sightDate.month} ${sightDate.date}`
                          : "Add to a day";
                      })()}
                    </button>
                    {/* Date Dropdown Popup */}
                    {dropdownOpen[index] && (
                      <div className="absolute bottom-12 bg-white shadow-lg border border-gray-300 rounded-md md:w-[87%] w-[90%]">
                        <ul
                          title="Select a date"
                          className="font-color-tertiary flex flex-col items-center font-medium justify-center outline-none rounded-md w-full"
                        >
                          {(() => {
                            const sightDate = getSightDateInItinerary(sight);
                            if (sightDate) {
                              return (
                                <li
                                  key={`remove-${sightDate.dayIndex}`}
                                  className="w-full hover:bg-red-200 text-center"
                                >
                                  <button
                                    className="dropdown-item block text-gray-700 w-full items-center gap-2 whitespace-nowrap py-1 px-2 text-left text-sm"
                                    onClick={(e) => {
                                      toggleDateDropdown(index);
                                      handleDeleteButtonClick(
                                        e,
                                        sight,
                                        sightDate.dayIndex,
                                        sight.googlePlaceId
                                      );
                                    }}
                                  >
                                    Remove from day
                                  </button>
                                </li>
                              );
                            }
                            return null;
                          })()}
                          {getAvailableDates(sight)
                            .filter(
                              (item) =>
                                item.dayIndex !==
                                getSightDateInItinerary(sight)?.dayIndex
                            )
                            .map((item) => {
                              const day = getDay(item.dayIndex);
                              const month = getMonth(item.dayIndex);
                              const date = getDate(item.dayIndex);

                              return (
                                <li
                                  key={`date-${item.dayIndex}`}
                                  className="w-full hover:bg-gray-200 text-center text-sm"
                                >
                                  <button
                                    className="dropdown-item block text-gray-700 w-full items-center gap-2 whitespace-nowrap py-1 px-2 text-left text-sm"
                                    onClick={(e) => {
                                      toggleDateDropdown(index);
                                      handleSelectClick(
                                        e,
                                        sight,
                                        item.dayIndex
                                      );
                                    }}
                                  >
                                    {`${day}, ${month}, ${date}`}
                                  </button>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center h-full">
          <h2 className="text-xl font-semibold text-gray-600">
            Explore sights loading...
          </h2>
        </div>
      )}

      {picture && (
        <div className="h-screen w-screen fixed top-0 left-0 flex items-center justify-evenly bg-black/75 z-[51]">
          <img
            src={picture}
            alt="WMW Hotels"
            className="h-auto w-3/4 max-h-[90%]"
          />
          <span
            className="absolute top-5 right-5 text-white text-2xl cursor-pointer"
            onClick={() => setPicture(null)}
          >
            <Close />
          </span>
        </div>
      )}

      {/* Full-screen popup for login */}
      {!isLoggedIn && showSignInPopup && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black/75 z-[100]"
          onClick={() => setShowSignInPopup(false)} // Close popup when clicking outside
        >
          <div
            className="relative bg-white p-6 pt-10 rounded-lg shadow-lg text-center"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
          >
            <button
              className="absolute top-3 right-3 text-3xl text-gray-800 hover:text-black"
              onClick={() => setShowSignInPopup(false)} // Close button
            >
              &times;
            </button>
            <h2 className="text-2xl font-semibold mb-4">Sign In Required</h2>
            <p className="text-lg">Please sign in to use this feature.</p>
            <button
              className="mt-4 px-6 py-2 bg-black text-white rounded-lg"
              onClick={googleAuth} 
            >
              <span className="flex items-center justify-center">
                <FcGoogle className="mr-1 text-lg" />
                <span translate="no">Sign In</span>
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
