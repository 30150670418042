import React from "react";

const CommunityTripsCard = ({ placeName, photoUrl, date, days, link, firstName, profilePhoto }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4 relative">
      <a
        href={link}
        onClick={() => {
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        }}
      >
        <img
          src={photoUrl}
          alt="Trip"
          className="w-min-40 sm:w-min-32 md:w-min-48 h-48 object-cover mb-4"
        />
        <h3 className="text-lg font-bold mb-2 text-[#FD630D]">{placeName}</h3>
        <p className="text-gray-600">{date}</p>
        <p className="text-gray-600">{days}</p>
      </a>
      <div className="flex items-center mt-4">
        <img
          src={
            profilePhoto
              ? profilePhoto
              : `https://ui-avatars.com/api/?name=${
                  firstName ? firstName : "A"
                }`
          }
          alt="User"
          className="w-10 h-10 rounded-full object-cover mr-2"
        />
        <p className="text-gray-800 font-medium">
          {firstName ? firstName : "User"}
        </p>
      </div>
    </div>
  );
};

export default CommunityTripsCard;
