import React from 'react';
import { MdOutlineTravelExplore } from "react-icons/md";
import { MdOutlineTour } from "react-icons/md";
import "../assets/css/style.css";
import SearchWidget from './search-widget';
function Widget() {
  return (<>
    <div className="p-4 border-2 bg-orange-500 rounded-lg">
        <h1 className="font-medium flex items-center text-white border-b-2 border-white text-base py-2"><MdOutlineTravelExplore className='mr-2 h-7 w-7'/> Create your own itinerary.</h1>
        <h1 className="font-medium flex items-center text-white text-base py-2"><MdOutlineTour className='mr-2 h-8 w-8'/> Explore Tours, Stays and Travel options.</h1>
    </div>
    <SearchWidget/>
    <div className="border-2 bg-orange-500 rounded-lg flex justify-center items-center">
      <div className="text-white flex flex-col items-start mb-6 justify-center w-full">
        <h4 className="text-base text-center w-full font-semibold lg:text-xl capitalize tracking-wide">
          SUBSCRIBE TO NEWSLETTER
        </h4>
        <div
          className="flex relative items-center justify-center w-[90%] rounded-sm mx-auto py-1.5"
          style={{ border: "2px solid white" }}
        >
          <input
            type="email"
            placeholder="Email"
            className="text-black input-placeholder outline-none bg-transparent w-full border-none px-2.5"
            style={{ borderRight: "1px solid white" }}
          />
          <a
            href="/"
            className="text-xs font-semibold cursor-pointer tracking-wide px-2.5"
          >
            SEND
          </a>
          <span className="text-xs italic absolute -bottom-5">
            *we don't spam
          </span>
        </div>
      </div>
    </div>
  </>
  )
}

export default Widget
