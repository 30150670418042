import React, { useCallback, useState } from "react";
import ReactSpinner from "./ReactSpinner";
import { RxCross2 as Clear } from "react-icons/rx";
import axios from "axios";
import { useStatus } from "./statusContext";

function GoogleSearchBox({
  setData,
  setInput,
  data,
  setShowEmptyError,
  input,
}) {
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const {showStatus} = useStatus()

  const debounce = (func, delay) =>{
      let timeoutId;
      return (...args) =>{
        if(timeoutId)clearTimeout(timeoutId)
        timeoutId = setTimeout(()=>{
         func(...args)
      }, delay)
      }

  }




  const handleChange = async (val) => {
    try {
      setShowEmptyError(false);
      setInput(val);
      setLoading(true);

      const encodeVal = encodeURIComponent(val);

      if (encodeVal === "") {
        setLoading(false)
        return setSuggestions(null);
      }

      return handleDebouncedFuncion(encodeVal)
    } catch (error){
      await showStatus("Error", error.message || "Unable to fetchData", "error", true)
    }
  };

  const ImportSuggestion = async (encodeVal) =>{
       try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/autocomplete/${encodeVal}`,
          {
            withCredentials: true,
          }
        );


        if (
          response.data &&
          response.data.data &&
          response.data.data.suggestions
        ) {
          setSuggestions(response.data.data.suggestions);
        } else {
          setSuggestions(null);
        }
       } catch (error) {
          showStatus("Error", error.message || "Unable to fetchData", "error", true)
          setSuggestions(null);
       }finally{
          setLoading(false);
       }
  }

  const handleClear = () => {
    setShowEmptyError(false);
    setInput("");
    setData(null);
    setSuggestions(null);
  };

  const handleData = (givenData, givenText) => {
    setData(JSON.parse(givenData));
    setInput(givenText);
    setSuggestions(null);
  };

  const handleDebouncedFuncion = useCallback(debounce(ImportSuggestion, 500), []) 


  return (
    <div className="relative flex items-center justify-center w-full mt-[0.1rem]">
      <input
        type="text"
        className="w-full py-[0.35rem] text-base font-normal px-2 pr-8 outline-none rounded-md border-2 border-solid border-gray-300 shadow-2xl focus:border-orange-500"
        placeholder="Search your destination"
        value={input}
        onChange={(ele) => handleChange(ele.currentTarget.value)}
      />

      {loading ? (
        <ReactSpinner />
      ) : input !== "" ? (
        <Clear
          className="absolute right-1.5 text-xl cursor-pointer text-orange-500"
          onClick={() => handleClear()}
        />
      ) : (
        <></>
      )}

      {suggestions ? (
        <span
          className={`absolute top-10 bg-white flex z-10 flex-col items-center rounded-md border-2 border-solid border-[#00000069] justify-center w-full `}
        >
          {suggestions.map((ele, index) => {
            return (
              <span
                key={ele.placePrediction.placeId + index}
                className="flex flex-col items-start justify-center py-0.5 px-1.5 text-left w-full cursor-pointer hover:bg-[#00000029]"
                onClick={() =>
                  handleData(
                    JSON.stringify(ele.placePrediction),
                    ele.placePrediction.structuredFormat.mainText.text
                  )
                }
              >
                <span className="text-left w-full font-semibold text-black text-base">
                  {ele.placePrediction.structuredFormat.mainText.text}
                </span>
                <span className="-mt-1 min-h-3 text-left w-full text-gray-500 text-sm">
                  {ele.placePrediction.structuredFormat.secondaryText !==
                  undefined
                    ? ele.placePrediction.structuredFormat.secondaryText.text
                    : ""}
                </span>
              </span>
            );
          })}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
}

export default GoogleSearchBox;
