import React from "react";

export default function () {
  return (
    <div className="w-full bg-black min-h-[50vh] py-10  mx-auto">
      <h1 className="text-center text-2xl text-white font-black">
        What does our beta users say about us
      </h1>
      <div className="w-[90%] mx-auto flex flex-col justify-evenly p-5 min-h-[50vh] rounded-2xl">
        <div className="flex flex-wrap -m-4">
          <div className="p-4 md:w-1/3 my-auto">
            <div className="h-auto border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
              <div className="p-6">
                <h2 className="tracking-widest text-xs text-center title-font font-medium text-[#fd360d] mb-1">
                  WanderMeWell
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  I am excited and enthralled with the idea of contact less
                  travel itinerary planning and getting it executed with an
                  agent. I need not speak with agent, and I can get the
                  personalized itinerary for me. I really like the
                  personalization on website too
                </h1>
              </div>
              <hr />
              <div className="max-h-16 w-full font-black text-center justify-center py-4 text-white">
                Nikhil, Software Developer
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3 my-auto">
            <div className="h-auto border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
              <div className="p-6">
                <h2 className="tracking-widest text-xs text-center title-font font-medium text-[#fd360d] mb-1">
                  WanderMeWell
                </h2>
                <h1 className="title-font text-lg font-medium h-auto text-white mb-3">
                  I am so excited to book my holiday plan with WanderMeWell. I
                  believe our trip will be a mesmerizing blend of culture,
                  nature, and adventure, made possible by the exceptional idea
                  of WanderMeWell. If you're also planning a trip, i
                  wholeheartedly recommend entrusting your journey to this
                  fantastic team.
                </h1>
              </div>
              <hr />
              <div className="h-16 w-full font-black text-center justify-center py-4 text-white">
                Veena, Sales Department
              </div>
            </div>
          </div>
          <div className="p-4 md:w-1/3 my-auto">
            <div className="h-auto border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
              <div className="p-6">
                <h2 className="tracking-widest text-xs text-center title-font font-medium text-[#fd360d] mb-1">
                  WanderMeWell
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  I am excited and enthralled with the idea of contact less
                  travel itinerary planning and getting it executed with an
                  agent. I need not speak with agent, and I can get the
                  personalized itinerary for me. I really like the
                  personalization on website too
                </h1>
              </div>
              <hr />
              <div className="h-16 w-full font-black text-center justify-center py-4 text-white">
                Nikhil, Software Developer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
