import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({
  hitType: "pageview",
  page: "/packages",
  title: "packages-page",
});

function PackagesPage() {
  const [packageData, setPackageData] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/package/`
        );
        setPackageData(response.data);
        // console.log(packageData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          WanderMeWell : Our AI Packages
        </title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <section className="flex px-6 items-center justify-center w-screen py-12 sm:px-8 lg:px-10">
        <div className="flex flex-col items-center justify-center w-full max-w-7xl">
          <span className="flex items-center justify-center gap-x-3">
            <span className="mb-1 w-10 border-[#fd630d] border-2 rounded-2xl mt-2.5 border-solid"></span>
            <h1 className="text-xl md:text-3xl font-semibold font-sans text-black">
              Popular Packages
            </h1>
            <span className="mb-1 w-10 border-[#fd630d] border-2 rounded-2xl mt-2.5 border-solid"></span>
          </span>

          <span className="grid py-8 max-w-5xl w-full gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {packageData.map((packageItem, index) => (
              <Link to={packageItem.link}>
                <span
                  key={index}
                  className="cursor-pointer flex h-48 w-full overflow-hidden items-center justify-center relative sm:h-72"
                >
                  <img
                    className="absolute object-cover h-full w-full"
                    src={packageItem.photolink}
                    alt=""
                  />
                  <span
                    className="flex p-5 flex-col items-start justify-start absolute top-0 left-0 bg-blend-darken h-full w-full"
                    style={{
                      background: "linear-gradient(180deg, black, transparent)",
                    }}
                  >
                    <h3 className="text-gray-200 font-sans font-semibold tracking-wider text-lg">
                      {packageItem.location}
                    </h3>
                    <span
                      className="w-8 mt-2"
                      style={{ border: "1.5px solid white" }}
                    ></span>
                  </span>
                </span>
              </Link>
            ))}
          </span>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PackagesPage;
