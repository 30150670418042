import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const BookNowForm = ({ selectedPackage, selectedRating }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [inputEmailValue, setInputEmailValue] = useState("");
  const [inputNameValue, setInputNameValue] = useState("");
  const [inputLocationValue, setInputLocationValue] = useState("");
  const [inputMessageValue, setInputMessageValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [buffer, setBuffer] = useState(false);

  const link = window.location.href;

  async function submitForm(event) {
    event.preventDefault();

    if (!isValidEmail(inputEmailValue)) {
      return setErrorMessage(
        inputEmailValue === ""
          ? "Email address should not be empty!"
          : "Please enter a valid email Id!"
      );
    }

    if (!isValidFullName(inputNameValue)) {
      return setErrorMessage(
        inputNameValue === ""
          ? "Please enter your name"
          : "Please enter your valid full name!"
      );
    }

    if (!phoneNumber || phoneNumber.length < 10) {
      return setErrorMessage("Please enter a valid mobile number!");
    }

    const formData = new FormData();
    setValidated(true);
    let dataVerification = inputEmailValue && isValidEmail(inputEmailValue);

    if (dataVerification) {
      setBuffer(true);
      try {
        formData.append("Email", inputEmailValue);
        formData.append("Name", inputNameValue);
        formData.append("Mobile", phoneNumber);
        formData.append("Location", inputLocationValue);
        formData.append("Message", inputMessageValue);
        formData.append(
          "Itinerary",
          `${selectedPackage?.title} (${selectedRating} stars) || ${link}`
        );

        await fetch(
          "https://script.google.com/macros/s/AKfycbzwUSeKUESzkn5fkn559cekHfz2YueqRmT2h7J5uRSAxCZdPyHbIIiQEzBqxM_LYpDs/exec",
          {
            method: "POST",
            body: formData,
          }
        );

        swal(
          "Form Received",
          "Thank you for your message! We'll get back to you!",
          "success"
        ).then(() => {
          setBuffer(false);
          setIsFormSubmitted(true);
          setErrorMessage("");

          // Reset all form fields including message
          setInputEmailValue("");
          setInputNameValue("");
          setPhoneNumber("");
          setInputLocationValue("");
          setInputMessageValue(""); // Resetting message field
        });
      } catch (error) {
        console.error("Error submitting the form:", error);
        swal(
          "Not Submitted",
          "Error in submitting the form. Please try later.",
          "error"
        ).then(() => {
          setBuffer(false);
        });
      }
    } else {
      setIsFormSubmitted(false);
      setBuffer(false);
      setErrorMessage("Please check all the fields once again.");
    }
  }

  function isValidFullName(value) {
    const fullNameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    return fullNameRegex.test(value);
  }

  function isValidEmail(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  }, [errorMessage]);

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto">
        <h2 className="text-2xl font-bold text-center mb-4">Book Now</h2>
        <form className="space-y-4" onSubmit={submitForm}>
          <div>
            <label className="block text-sm font-semibold" htmlFor="email">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={inputEmailValue}
              onChange={(e) => setInputEmailValue(e.target.value)}
              className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#fd630d]"
              placeholder="name@example.com"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={inputNameValue}
              onChange={(e) => setInputNameValue(e.target.value)}
              className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#fd630d]"
              placeholder="Enter your name"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold" htmlFor="phone">
              Phone Number
            </label>
            <PhoneInput
              country={"in"}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              inputStyle={{
                width: "100%",
                padding: "0.5rem",
                paddingLeft: "3rem",
                border: "1px solid #D1D5DB",
                borderRadius: "0.375rem",
              }}
            />
          </div>
          <div>
            <label className="block text-sm font-semibold" htmlFor="location">
              Starting Location
            </label>
            <input
              type="text"
              id="location"
              value={inputLocationValue}
              onChange={(e) => setInputLocationValue(e.target.value)}
              className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#fd630d]"
              placeholder="Bangalore, India"
            />
          </div>
          <div>
            <label className="block text-sm font-semibold" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              value={inputMessageValue}
              onChange={(e) => setInputMessageValue(e.target.value)}
              rows={3}
              className="w-full mt-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-[#fd630d]"
              placeholder="Enter your message"
            />
          </div>
          <div>
            {errorMessage && (
              <p className="text-red-700 text-center">{errorMessage}</p>
            )}
          </div>
          <button
            type="submit"
            className={`inline-block text-center font-semibold rounded py-2 px-4 leading-normal no-underline ${
              !buffer ? "bg-[#fd630d]" : "bg-orange-400"
            } text-white w-full`}
          >
            {!buffer ? "Submit" : "Processing..."}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BookNowForm;
