import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import aboutImage from "../assets/images/download-app.webp";
import SubscribeForm from "../components/SubscribeForm";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";


ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.send({
  hitType: "pageview",
  page: "/downloadApp",
  title: "download-app-page",
});

gsap.registerPlugin(ScrollTrigger);

const DownloadAppPage = () => {
  const HeroContent = useRef();

  useEffect(() => {
    const el2 = HeroContent.current;
    gsap.fromTo(
      el2,
      { delay: 1, translateX: "-50px", opacity: 0 },
      {
        delay: 0.5,
        translateX: "0.55px",
        opacity: 1,
        duration: 2,
        scrollTrigger: { trigger: el2 },
      }
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>
          WanderMeWell : Download our app
        </title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <div className="pb-5  text-black">
        <div id="downloadApp" className="container pt-5 overflow-hidden">
          <div className="flex flex-col md:flex-row" ref={HeroContent}>
            <div className="md:w-1/2 px-5">
              <div className="flex justify-center items-center">
                <img
                  src={aboutImage}
                  alt=""
                  className="w-full h-72 md:h-full md:max-w-md"
                />
              </div>
            </div>
            <div className="md:w-1/2 p-5 mt-6 md:mt-0 md:py-6 flex flex-col justify-center">
              <div className="flex flex-col justify-center items-center">
                <h1 className="font-bold text-3xl md:text-5xl text-center mb-4">
                  <span>Join our waitlist!</span>
                  <br />
                </h1>
                <p className="text-lg text-center">
                  Sorry, the number of active app participants is full. Please
                  join our waitlist to be notified when you're eligible to
                  download the app.
                </p>
                <SubscribeForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DownloadAppPage;
