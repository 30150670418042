import { useState, useEffect } from "react";
import React from "react";
import BlogCard from "./BlogCard";
import axios from "axios";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Footer from "./Footer";
import { Helmet } from "react-helmet";


function Blog() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(9);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blogposts`, {
          params: { page, limit }
        });
        setData(response.data.results);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };
    fetchPosts();
  }, [page, limit]);

  const handlePrevPage = () => {
    setPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleNextPage = () => {
    setPage(prevPage => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  return (
    <>
      <Helmet>
        <title>WanderMeWell : Blogs</title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <div className="min-h-screen">
        <h1 className="text-black font-bold text-4xl text-center py-6">
          Blogs
        </h1>
        <hr className="bg-gray-300 h-0.5 w-auto container mx-auto" />
        <div className="flex justify-center lg:flex-row flex-col">
          <div className="lg:w-[70%] min-h-screen flex flex-col py-4">
            <div className="w-full flex flex-wrap">
              {data &&
                data.map((item, index) => <BlogCard key={index} data={item} />)}
            </div>
            <div className="flex justify-center mt-4">
              <button
                className="px-4 py-2 m-1 text-white bg-orange-500 rounded hover:bg-orange-600"
                onClick={() => {
                  
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                  handlePrevPage();
                }}
              >
                <FaArrowLeft />
              </button>
              <span className="px-4 py-2 m-1 text-lg font-semibold">{`${page}/${totalPages}`}</span>
              <button
                className="px-4 py-2 m-1 text-white bg-orange-500 rounded hover:bg-orange-600"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                  handleNextPage();

                }}
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
          <div className="lg:w-[20%] lg:min-h-screen p-3">
            <h1 className="text-center py-4 text-xl font-black text-[#ff5e00]">
              Most Popular
            </h1>
            <hr className="bg-gray-300 h-0.5 w-auto container mx-auto" />
            <ul className="list-style-none">
              {data &&
                data.map(
                  (item, index) =>
                    index < 5 && (
                      <a href={`/blog/${item.link}`}>
                        <li className="text-center text-md lg:text-sm py-4 font-semibold hover:scale-110 transition-all hover:text-[#ff7745]">
                          {item.title}
                        </li>
                      </a>
                    )
                )}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blog;
