import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import TermsAndConditions from "./components/TermsAndConditions";
import LandingPage from "./screens/LandingPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import Blog from "./components/Blog";
import DownloadAppPage from "./screens/DownloadAppPage";
import Itinerary from "./screens/Itinerary";
import Hotels from "./screens/Hotels";
import ServicePage from "./screens/ServicePage";
import DownloadPdf from "./screens/downloadPdf";
import BlogPage from "./components/BlogPage";
import CreateItinerary from "./screens/CreateItineraryPage";
import axios from "axios";
import PackagesPage from "./screens/PackagesPage";
import YourTrips from "./screens/YourTrips";
import WhatsAppAssistance from './components/WhatsAppAssistance';
import CommunityTripsPage from "./screens/CommunityTripsPage";
import TravelPage from "./screens/TravelPage";

function App() {
  const [user, setUser] = useState(null);

  const getUser = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
      const { data } = await axios.get(url, { withCredentials: true });
      
      const storedUserName = localStorage.getItem("userName");
      const storedUserId = localStorage.getItem("userId");
      const storedUserPhoto = localStorage.getItem("userPhoto");

      if (
        data.user.displayName !== storedUserName ||
        data.user.id !== storedUserId ||
        data.user.photos[0].value !== storedUserPhoto
      ) {
        localStorage.setItem("userName", data.user.displayName);
        localStorage.setItem("userId", data.user.id);
        localStorage.setItem("userPhoto", data.user.photos[0].value);

        setUser(data.user);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getUser();

    if (user) {
      //alert("window getting reloaded")
      window.location.reload();
    }
  }, [user]);

  return (
    <div className="App">
      <Router>
        <Navbar user={user} />
        <div className="pages">
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/services" element={<ServicePage />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/termsAndConditions" element={<TermsAndConditions />}/>
            <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/downloadApp" element={<DownloadAppPage />} />
            <Route exact path="/blog/:link" element={<BlogPage />} />
            <Route exact path="/home" element={<LandingPage />} />
            <Route exact path="/hotels/:id" element={<Hotels />} />
            <Route exact path="/itinerary/:id" element={<Itinerary />} />
            <Route exact path="/create-itinerary/:id" element={<CreateItinerary />} />
            <Route exact path="/itinerary/:id/download-pdf" element={<DownloadPdf />}/>
            <Route exact path="/packages" element={<PackagesPage />} />
            <Route exact path="/your-trips" element={<YourTrips />} />
            <Route exact path="/community-trips" element={<CommunityTripsPage />} />
            <Route exact path="/india/meghalaya-trip-mgh10" element={<TravelPage />} />
          </Routes>
        </div>
        <WhatsAppAssistance />
      </Router>
    </div>
  );
}

export default App;