import React from "react";
import aboutImage from "../assets/images/about.webp";
import Footer from "./Footer";
import { Helmet } from "react-helmet";


function About() {
  return (
    <>
      <Helmet>
        <title>WanderMeWell : About Us</title>
        <meta
          name="description"
          content="Revolutionize your travel itineraries with WanderMeWell AI, the ultimate tool for modern travelers. Our advanced AI trip and travel planner capabilities ensure seamless itinerary planning and booking. Experience the ease of crafting AI-optimized itineraries tailored to your preferences. Plan your trip effortlessly with our innovative technology and start exploring smarter today!"
        />
        <meta
          name="keywords"
          content="WanderMeWell, Wander, Well, AI, Trip Planner AI, AI Trip Planner, AI Itineraries, Travel AI, AI Vacation Planner, Travel Itinerary AI, AI for Travel, AI to Plan Trip, Itinerary Planner, Travel Planner, Google Sheets Itinerary Template, AI Trip Planner Free, Travel Itinerary Template Google Sheets, Optimal Route Planning, Personalized Recommendations, Budget-Friendly Dining, Social Media Integration"
        ></meta>
      </Helmet>
      <div className="bg-black min-w-screen min-h-screen">
        <div
          className="w-full min-h-[50vh] py-10"
          style={{
            background: `url("https://wallpapertag.com/wallpaper/full/4/7/d/221111-travel-background-2560x1600-hd-for-mobile.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <div className="w-[90%] mx-auto flex flex-col justify-evenly p-5 min-h-[50vh] rounded-2xl bg-gray-400 bg-opacity-40">
            <h1 className="text-start text-2xl text-black font-black">
              Experience the joy of hassle free Travel Planning! <br /> with{" "}
              <span className="text-[#fd360d]">WanderMeWell</span>
            </h1>
            <p className="py-5 text-lg font-semibold">
              Thinking of taking a break from busy Life? <br />
              Planning to go out to spend some quality time? <br />
              Let us, with our AI enabled platform help you!
            </p>
            <h3 className="font-bold text-black">
              Start Planning your Trip with us , Now!!!
            </h3>
          </div>
        </div>
        <hr />
        <div className="w-full min-h-[60vh] py-10">
          <iframe
            className="lg:h-[75vh] h-[45vh] w-[70vw] mx-auto"
            src="https://www.youtube.com/embed/c4NC3zRP6D8?si=xYthYZBDvPF_gOJP&rel=0&controls=0&end=34&cc_load_policy=1"
            title="YouTube video player"
          />
        </div>
        <hr />
        <div className="w-[95%] mx-auto min-h-[50vh] py-10 flex justify-around flex-wrap">
          <div className="w-auto justify-center flex m-auto">
            <img
              src={aboutImage}
              className="h-80 w-100 max-w-[95vw] rounded-2xl"
              alt="WanderMeWell"
            />
          </div>
          <div className="w-auto mx-auto flex flex-col justify-evenly p-5 min-h-[50vh]">
            <h1 className="text-start text-2xl text-white font-black text-wrap">
              Explore the World, Leave the Rest to Us
            </h1>
            <p className="py-5 text-lg font-semibold text-white text-wrap">
              Start your travel journey with us. At{" "}
              <span className="text-[#fd360d]">WanderMeWell</span>, with our AI
              enabled platform <br /> we offer all-inclusive travel services
              that cater to your every need. <br /> Our AI enabled platform
              suggests destinations and create travel itineraries. <br /> From
              destination ideas and travel planning to booking your dream trip,
              we've got you covered!
            </p>
          </div>
        </div>
        <hr />
        <div className="w-full min-h-[50vh] py-10  mx-auto">
          <h1 className="text-center text-2xl text-white font-black">
            What does our beta users say about us
          </h1>
          <div className="w-[90%] mx-auto flex flex-col justify-evenly p-5 min-h-[50vh] rounded-2xl">
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/3 my-auto">
                <div className="h-auto border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs text-center title-font font-medium text-[#fd360d] mb-1">
                      WanderMeWell
                    </h2>
                    <h1 className="title-font text-lg font-medium text-white mb-3">
                      I am excited and enthralled with the idea of contact less
                      travel itinerary planning and getting it executed with an
                      agent. I need not speak with agent, and I can get the
                      personalized itinerary for me. I really like the
                      personalization on website too
                    </h1>
                  </div>
                  <hr />
                  <div className="max-h-16 w-full font-black text-center justify-center py-4 text-white">
                    Nikhil, Software Developer
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/3 my-auto">
                <div className="h-auto border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs text-center title-font font-medium text-[#fd360d] mb-1">
                      WanderMeWell
                    </h2>
                    <h1 className="title-font text-lg font-medium h-auto text-white mb-3">
                      I am so excited to book my holiday plan with WanderMeWell.
                      I believe our trip will be a mesmerizing blend of culture,
                      nature, and adventure, made possible by the exceptional
                      idea of WanderMeWell. If you're also planning a trip, i
                      wholeheartedly recommend entrusting your journey to this
                      fantastic team.
                    </h1>
                  </div>
                  <hr />
                  <div className="h-16 w-full font-black text-center justify-center py-4 text-white">
                    Veena, Sales Department
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/3 my-auto">
                <div className="h-auto border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs text-center title-font font-medium text-[#fd360d] mb-1">
                      WanderMeWell
                    </h2>
                    <h1 className="title-font text-lg font-medium text-white mb-3">
                      I am excited and enthralled with the idea of contact less
                      travel itinerary planning and getting it executed with an
                      agent. I need not speak with agent, and I can get the
                      personalized itinerary for me. I really like the
                      personalization on website too
                    </h1>
                  </div>
                  <hr />
                  <div className="h-16 w-full font-black text-center justify-center py-4 text-white">
                    Nikhil, Software Developer
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
