import React, { useState ,useRef, useEffect} from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';

function WhatsAppAssistance() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [message, setMessage] = useState('');
  const popupRef = useRef(null);
  const handleButtonClick = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleSendMessage = async () => {
    try {
        const messageToBeSent = `${message}
        For assistance :
        Reference URL : ${window.location.href}`;
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/sendMessage`, { message: messageToBeSent });
        const { whatsappUrl } = response.data;
        // Navigate to the WhatsApp URL
        window.open(whatsappUrl, "_target");
        setIsPopupVisible(false);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  useEffect(() => { 
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('enter', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('enter', handleClickOutside);
    };

  }, []);
  return (
    <>
      <div className="fixed z-[5] bottom-12 lg:bottom-8 right-4">
        <div
          className="p-3 md:p-4 bg-green-500 rounded-full shadow-lg flex items-center justify-center hover:bg-green-600 transition duration-300 cursor-pointer"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Get assistance on WhatsApp!"
          onClick={handleButtonClick}
        >
          <FaWhatsapp className="text-xl md:text-2xl text-white font-black animate-pulse" />
        </div>
        <Tooltip place="left" type="dark" effect="solid" id="my-tooltip" />

        {isPopupVisible && (
          <div className="absolute bottom-16 right-0 bg-gray-100 p-4 rounded-md shadow-lg w-80" ref={popupRef}>
            <h2 className="text-lg font-semibold mb-2">
              Get assistance on WhatsApp!
            </h2>
            <textarea
              className="w-full p-2 border rounded-md mb-2"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
            />
            <div className="flex justify-end">
              <button
                className="border border-gray-500 text-black py-1 px-4 rounded-md mr-2 font-bold"
                onClick={() => setIsPopupVisible(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-600 text-white py-1 px-4 rounded-md font-bold"
                onClick={handleSendMessage}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default WhatsAppAssistance;
