import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Widget from "./widget";
import Footer from "./Footer";

export default function BlogPage() {
  const [posts, setPosts] = useState({});
  const { link } = useParams();
  const fetchData = async () => {
    //console.log("fetching blog");
    let url = `${process.env.REACT_APP_API_URL}/api/blogposts/${link}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    //console.log(json);
    setPosts(json);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{posts.title}</title>
        <meta name="description" content="WanderMeWell" />
        <meta
          name="keywords"
          content="WanderMeWell, travel, blog, explore, discover"
        />
        <meta name="description" content={posts.subtitle} />
        <meta name="keywords" content={posts.subtitle} />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />

        <meta name="twitter:card" content="image" />
        <meta name="twitter:title" content={posts.title} />
        <meta name="twitter:description" content={posts.subtitle} />
        <meta name="twitter:url" content="https://wandermewell.com" />
        <meta name="twitter:creator" content="@wandermewell" />
        <meta name="twitter:site" content="@wandermewell" />

        <meta property="og:site_name" content="WanderMeWell" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content="https://wandermewell.com" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={posts.title} />
        <meta property="og:description" content={posts.subtitle} />

        <meta property="article:publisher" content="WanderMeWell" />
        <meta property="article:author" content="Wandermewell" />
        <meta
          property="article:published_time"
          content="2024-04-27T04:00:00.000Z"
        />
        <meta
          property="article:modified_time"
          content="2024-04-27T04:00:00.000Z"
        ></meta>
      </Helmet>

      <div className="w-screen max-w-screen-xl mx-auto flex flex-wrap">
        <div className="lg:w-3/4 w-full py-5">
          <div className="w-full max-w-screen-md flex justify-center ml-auto">
            <div
              className="container px-2"
              dangerouslySetInnerHTML={{ __html: posts.content }}
            />
          </div>
        </div>
        <div className="lg:w-1/4 min-w-[20rem] max-w-96 mx-auto w-full py-5">
          <Widget />
        </div>
      </div>
      <Footer />
    </>
  );
}
